<style>
input.twdel {
  width: 24px;
  height: 24px;
}

table.tweets .posted {
  color: #44a;
}

table.tweets th {
  background-color: #777;
  border: 1px solid #555;
  border: 1px solid #555;
  padding: 1px 4px;
}

table.tweets td {
  border: 1px solid #555;
  padding: 4px;
}

td.red {
  font-weight: bold;
  background-color: rgb(156, 23, 23) !important;
}


td.grey {
  font-weight: bold;
  color: rgb(181, 181, 181);
}

td.green {
  font-weight: bold;
  color: rgb(76, 172, 52);
}
</style>

<template>
  <bank-navi :user="user"></bank-navi>
  <div class="py-0 container-fluid">
    <div class="col-lg-12 col-12">
      <vue-search :apibase="apibase" />
    </div>
    <span class="title">Tweets</span>
    <button :class="{ enable: modeClass('normal') }" class="btn btnx url-mode normal" @click="setTwMode('normal')">
      未取込み
    </button>
    <button :class="{ enable: modeClass('captured') }" class="btn btnx url-mode captured" @click="setTwMode('captured')">
      取込み済
    </button>
    <button :class="{ enable: modeClass('rejected') }" class="btn btnx url-mode rejected" @click="setTwMode('rejected')">
      Reject
    </button>
    <button :class="{ enable: modeClass('unfinished') }" class="btn btnx url-mode unfinished"
      @click="setTwMode('unfinished')">
      未処理/エラー
    </button>

    <div class="row">
      <div class="row my-4">
        <div class="col-lg-12col-md-12 mb-md-0 mb-4">
          <button class="button green pager" @click="prevPage">prev ◀</button>
          ページ {{ getPage() }}
          <button class="button green pager" @click="nextPage">▶ next</button>
          <button @click="deleteChecked" class="btn-delete" title='チェック済みを削除'>削除</button>
          <div class="col-lg-12col-md-12 mb-md-0 mb-4">
            <table class="tweets">
              <tr>
                <th>date</th>
                <th>[X]</th>
                <th>id</th>
                <th>text</th>
                <th>len</th>
                <th>isTweet</th>
                <th>isLINE</th>
                <th>result</th>
              </tr>
              <tr v-for="(oo, index) in  list " :key="index" :class="getPostedClass(oo.isPost)">
                <td>JP: {{ jpDate(oo.date) }}<br>
                  <span> VN: {{ vnDate(oo.date) }}</span><br>
                  {{ oo.date }}
                </td>
                <td> <input class="twdel" @click="delCheck(index)" type="checkbox" /> </td>
                <td @click="preview(oo.tid)">{{ oo.tid }}</td>
                <td @click="preview(oo.tid)">{{ oo.text }}<br>
                  <a href="#" @click="preview(oo.tid)">preview </a>
                </td>
                <td>{{ oo.text.length }}</td>
                <td>{{ oo.isPost }}</td>
                <td>{{ oo.isLINE }}</td>
                <td :class=tweetResult(oo)>{{ oo.result }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <a class="btn bg-gradient-success mt-1" href="#" type="button" @click="back">もどる</a>
  </div>
</template>
<script>
import BankNavi from "@/components/UrlbankNavi.vue";
import {
  getSiteData,
  getAPIBASE,
  onGetTweets,
  //onPreviewID,
  OnDeleteTweets,
} from "../Repository";
export default {
  name: "Urls",
  components: {
    BankNavi,
  },
  data() {
    return {
      delAry: [],
      twMode: "normal",
      runner: false,
      page: 0,
      pageRej: 0,
      pageCap: 0,
      pageErr: 0,
      noCaptured: true,
      render: 0,
      cnt: 0,
      apibase: getAPIBASE(),
    };
  },
  computed: {
    user() {
      return this.$store.state.mail;
    },
    list() {
      return this.$store.state.tweets;
    },
  },
  updated() { },
  mounted() {
    this.loadTweets();
    getSiteData();
  },
  methods: {
    tweetResult(obj) {
      if (obj.result == null || !("result" in obj)) {
        return "grey";
      }
      if (obj.result)
        return "green";
      return "red";
    },
    getPostedClass(flg) {
      if (flg) return "posted";
      return "";
    },
    jpDate(date) {
      const mydateUTC = new Date(date);
      const jstTime = new Date(mydateUTC.getTime());
      const jstDateString = jstTime.toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' });

      return jstDateString
    },
    vnDate(date) {
      const mydateUTC = new Date(date);
      const jstTime = new Date(mydateUTC.getTime());//+ (jstOffset * 60 * 1000));
      const jstDateString = jstTime.toLocaleString('ja-JP', { timeZone: 'Asia/Ho_Chi_Minh' });

      return jstDateString
    },

    fullUrl(url) {
      return "https://" + url;
    },
    delIndex(idx) {
      for (var ii = 0; ii < this.delAry.length; ii++) {
        if (idx == this.delAry[ii]) {
          delete this.delAry[ii]
        }
      }

    },
    delCheck(idx) {
      if (this.delAry.includes(idx))
        this.delIndex(idx);
      else
        this.delAry.push(idx);
      console.log(this.delAry)
    },
    deleteChecked() {
      var checkboxes = document.getElementsByClassName("twdel");
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == "checkbox") {
          checkboxes[i].checked = false;
        }
      }
      OnDeleteTweets(this.delAry);
    },
    getPage() {
      var page = this.page;
      if (this.twMode == "captured") {
        page = this.pageCap;
      }
      if (this.twMode == "rejected") {
        page = this.pageRej;
      }

      if (this.twMode == "unfinished") {
        page = this.pageErr;
      }
      return page;
    },

    modeClass(mode) {
      return mode == this.twMode;
      //  if (mode == this.twMode) return "enable";
      //  return "disable";
    },
    setTwMode(mode) {
      this.twMode = mode;
      this.loadTweets();
    },
    loadTweets() {
      onGetTweets();
    },
    delAC(id) {
      if (confirm("本当に削除して良いですか？\n\nid = " + id)) {
        //onDelURL(id);
        this.loadTweets();
      }
    },
    reload() {
      this.cnt = 0;
      this.loadTweets();
    },
    /*
    preview(idx) {
      this.$store.commit("onPageFilter", this.pageFilter);
      onPreviewID(this.$store.state.pages[idx].id);
    },*/

    nextPage() {
      this.cnt = 0;
      if (this.twMode == "rejected") {
        this.pageRej += 1;
      } else if (this.twMode == "captured") {
        this.pageCap += 1;
      } else if (this.twMode == "unfinished") {
        this.pageErr += 1;
      } else {
        this.page += 1;
      }
      this.loadTweets();
    },
    prevPage() {
      this.cnt = 0;
      if (this.twMode == "rejected") {
        if (this.pageRej == 0) return;
        this.pageRej -= 1;
      } else if (this.twMode == "captured") {
        if (this.pageCap == 0) return;
        this.pageCap -= 1;
      } else if (this.twMode == "unfinished") {
        if (this.pageErr == 0) return;
        this.pageErr -= 1;
      } else {
        if (this.page == 0) return;
        this.page -= 1;
      }
      this.loadTweets();
    },
    branch(url) {
      this.$router.push(url);
    },
    preview(id) {
      this.$router.push("/page-preload/" + id);
    },
    back(date) {
      console.log(date);
      this.$router.push("/admin/members");
    },
  },
};
</script>
