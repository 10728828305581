<style>
input.replace {
  width: 98%;
}
</style>

<template>
  <td width="45%">
    <input class="replace" type="text" @change="change" id="class" v-model="node[0]" />
  </td>
  <td width="45%">
    <input class="replace" type="text" @change="change" id="tag" v-model="node[1]" />
  </td>
  <td width="50%">
    <button @click="onDelete">削除</button>
  </td>
</template>

<script>
export default {
  name: "TagInput",
  props: ["dataIn", "index"],
  data() {
    return {
      node: [],
    };
  },
  mounted() {
    this.node = this.dataIn;
  },
  methods: {
    change() {
      this.$emit("emchange", { idx: this.index, node: this.node });
    },
    onDelete() {
      this.$emit("emdelete", this.index);
    },
  },
};
</script>
