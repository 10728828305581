<style>
button.btn.auto-capture {
  background-color: rgb(252, 145, 143) !important;
  color: #fff;
  margin: 0;
  margin-left: 40px !important;
  font-size: 1em !important;
}

button.btn.toData {
  color: #fff;
  background-color: rgb(36, 70, 133) !important;
  margin-right: 80px;
  margin-left: 80px;
  font-size: 1em !important;
}

td {
  vertical-align: middle;
}

.month-node {
  background-color: rgb(167, 217, 147);
  float: left;
  border: 2px solid rgb(159, 196, 172);
  padding: 5px 10px;
  margin: 10px 20px;
  color: #111;
  font-size: 1.2em;
  border-radius: 12%;
}

button#autoBtn.autoRunner {
  background-color: rgb(102, 143, 208) !important;
  color: #f00 !important;
}

.month-node:hover {
  background-color: rgb(191, 241, 171);
  font-weight: bold;
}

button.btnx {
  padding: 8px 18px !important;
  font-size: 0.7em !important;
  background-color: #cceccc !important;
}

button.pager:hover {
  border: solid 3px #02ff4ea5;
}

button.pager {
  border-radius: 40%;
  border: solid 3px #e6e1e170;
}

table td {
  word-wrap: break-word;
}

button.enable {
  border: solid 2px #00ff00 !important;
}

button.disable {}

button.url-mode {
  margin-left: 5px;
  border: solid 2px #5e6a5d;
  background-color: #2d3741 !important;
  color: #eee;
}

span.title {
  font-size: 1.6em;
  font-weight: bold;
  margin: 3px 10px;
}

.btn-clear {
  margin: 0px 0px 0px 16px;
  padding: 2px 6px;
  border-radius: 8px;
  color: #fff;
  background-color: darkcyan;
}

a.build {
  background-color: #7f98f2;
}

a.puball {
  background-color: #206e6d;
}


a.poling {
  background-color: #6cbab0;
}

a.deploy {
  background-color: #f44f93;
}

table.factory td:nth-child(1) {
  width: 120px;
}


table.factory td:nth-child(3) {
  max-width: 320px;
}

table.factory td:nth-child(2) {
  width: 100px;
}


table.factory {
  border: solid 1px #32395c;
}

table.factory td {
  padding: 0px 4px;
  border-bottom: solid 1px #323b62;
  border-right: solid 1px #323b62;
}

table.factory a {
  color: #efb6b6;
  width: 160px;
  border-right: #ccc 2px solid;
  border-bottom: #ccc 2px solid;
  margin: 20px;
  padding: 10px;
}

img.build {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
}

a:hover {
  background-color: #b2deb2;
}

.msgbuild {
  padding-top: 50px;
  width: 294px;
  margin-left: 200px;
}
</style>

<template>
  <bank-navi :user="user"></bank-navi>
  <div class="py-0 container-fluid">
    <div class="row">
      <h2 class="title">Factory</h2>
    </div>
    <p> 未ビルド記事 = {{ $store.state.factoryStat.pubCnt }}</p>
    <table class="factory">
      <tr>
        <td>
          <a href="#" @click="cmdBuild()" class="btn build"> ビルド </a>
        </td>
        <td>
          {{ dateStr($store.state.factoryStat.lastBuild) }}
        </td>
        <td @click="loadLog">
          このツールでPublish!した記事をビルド、 VPRESS Web(テスト環境, factory.vpress.jp)に反映します。
          <br>
          <template v-if="isBuild">
            <div class="msgbuild">
              <img src="/build.gif" class="build" />
              <P>ビルド中です。そのままお待ち下さい。</P>
            </div>
          </template>
        </td>
        <td>
          <a href="https://factory.vpress.asia" target="_blank">テスト環境へ</a>
        </td>
      </tr>
      <tr>
        <td>
          <a href="#" @click="cmdDeploy()" class="btn deploy"> deploy </a><br>
        </td>
        <td>
          {{ dateStr($store.state.factoryStat.lastDeploy) }}
        </td>

        <td>
          テスト環境から本番(S3)環境へデプロイ<br>
          <template v-if="isDeploy">
            <div class="msgbuild">
              <img src="/build.gif" class="build" />
              <P>デプロイ中です。そのままお待ち下さい。</P>
            </div>
          </template>

        </td>
        <td>
          <a href="https://www.vpress.asia" target="_blank">本番環境へ</a>
        </td>

      </tr>
      <tr>
        <td>
          <a href="#" @click="polling()" class="btn poling"> poling </a>
        </td>
        <td>
        </td>
        <td>
          表示が変わらないときにはこのボタンを押すと治るときがあります
        </td>
      </tr>
      <tr>
        <td>
          <a href="#" @click="cmdPuball()" class="btn puball"> すべての記事を再出力 </a>
        </td>
        <td>
          {{ dateStr($store.state.factoryStat.lastPublish) }}
        </td>

        <td>
          記事テンプレートを変更し、過去のすべての記事を再ビルドするときはこちらを使用します。
          （基本使わない）
          <br>
          <template v-if="isPublishAll">
            <div class="msgbuild">
              <img src="/build.gif" class="build" />
              <P>すべての記事のエクスポート中です。そのままお待ち下さい。</P>
            </div>
          </template>

        </td>
      </tr>
    </table>
    <br>
    <h3>(build後のdmesg)</h3>
    <div class="code-frame">
      <code id="code-log" class="language-javascript">{{ log }}</code>
    </div>

    <br>
  </div>
</template>
<script>

//import Datepicker from "vue3-datepicker";
import "vue3-datepicker/dist/vue3-datepicker.css";
import BankNavi from "@/components/UrlbankNavi.vue";
import {
  loadSys,
  onFactoryBuild,
  onFactoryPubAll,
  onFactoryDeploy,
  onFactoryPoling,
  getAPIBASE,
  //getHeader
} from "../Repository";

export default {
  name: "Pages",
  components: {
    BankNavi
  },

  data() {
    return {
      log: "--- log file ---",
      limit: 25,
      render: 0,
      //nbuild: false,
      pageFilter: {
        acAll: true,
        published: false,
        date: "",
        isMonth: "true",
      },
      apibase: getAPIBASE(),
    };
  },
  computed: {
    user() {
      return this.$store.state.mail;
    },
    list() {
      return this.$store.state.pages;
    },
    isBuild() {
      return this.$store.state.factoryBuild;
    },
    isDeploy() {
      return this.$store.state.factoryDeploy;
    },
    isPublishAll() {
      return this.$store.state.factoryPubAll;
    }

  },
  updated() { },
  mounted() {
    loadSys();
    this.loadLog();
  },
  methods: {
    dateStr(isodate) {
      if (!isodate) return "";
      var dt = isodate.split("T")[0].split("-");
      var tt = isodate.split("T")[1].split(":");
      return `${dt[0]} ${dt[1]}/${dt[2]} ${tt[0]}:${tt[1]}`;
    },
    cmdBuild() {
      console.log("build")
      onFactoryBuild();
      setTimeout(this.polling, 6000)
    },

    cmdPuball() {
      console.log("re publish all")
      onFactoryPubAll();
      setTimeout(this.cmdPoling, 5000)
    },

    cmdDeploy() {
      console.log("deploy")
      onFactoryDeploy();
      setTimeout(this.cmdPoling, 5000)
    },
    loadLog() {
      const url = 'https://urlbank.vpress.asia/log/dmesg.log';
      fetch(url, { cache: "reload" })
        .then(response => response.text())
        .then(data => {
          console.log(data)
          this.log = data;
        })
        .catch(error => {
          console.error('ファイルの読み込みエラー:', error);
        });
    },
    polling() {
      console.log("polling-build")
      onFactoryPoling();
      if (this.$store.state.factoryBuild ||
        this.$store.state.factoryDeploy || this.$store.state.factoryPubAll) {
        setTimeout(this.polling, 2500)
      } else {
        this.loadLog();
      }
    },
  },

};
</script>
<style>
.code-frame {
  background-color: rgb(7, 7, 30);
  border: #eee;
  border-radius: 10px;
  padding: 10px;
}

code {
  background-color: rgb(7, 7, 30) !important;
  white-space: pre !important;
  color: #ccc !important;
}
</style>