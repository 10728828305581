<style>
.unavi {
  background-color: #375878;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.unavi button {
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  background-color: #2a596d;
  padding: 2px 18px;
  border: solid #00aacc 2px;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
  border-radius: 7px;
  transition: 565ms;
  transform: translateY(0);
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 2px 5px;
  min-width: 100px;
}

span.user {
  position: fixed;
  right: 0;
  margin-right: 8px;
  font-size: 16px;
}

.unavi button:hover {
  transition: 565ms;
  transform: translateY(-0px);
  background-color: #fff;
  color: #0066cc;
  border: solid 2px #0066cc;
}

.local {
  background-color: #b16e6e;
}

.build {
  font-size: 0.5em;
  padding: 6px 4px;
  background-color: #fa3535;
  border: solid 2px #ffffff;
}
</style>

<template>
  <div class="py-0 container-fluid unavi" :class="naviClass"></div>
  <div v-bind:class="naviClass">
    <div class="col-lg-12 col-12">
      <span>UrlBank </span>
      <button class="" @click="branch('/Urls')">URL</button>
      <button class="" @click="branch('/Pages')">記事</button>
      <button class="" @click="branch('/crawler')">読取設定</button>
      <button class="" @click="branch('/replace')">置換</button>
      <button class="" @click="branch('/daily')">Daily</button>
      <button class="" @click="branch('/factory')">Factory</button>
      <button class="" @click="branch('/tweets')">Tw</button>
      <button class="" @click="branch('/video')">動画</button>
      <span v-if="isBuild" class="build">ビルド中</span>
      <span class="user">{{ user }}さん </span>
    </div>
  </div>
</template>

<script>
import { getAPIBASE } from "../Repository";
export default {
  name: "BankNavi",
  props: {
    user: String,
  },
  data() {
    return {
      active: false,
      naviClass: "",
      isBuild: false,
      logStat: "",
      stat: "",
    }
  },
  mounted() {
    this.active = true;
    this.polling();

    if (getAPIBASE().indexOf("127.0.0.1") >= 0) {
      this.naviClass = "py-0 container-fluid unavi local";
    }
    else
      this.naviClass = "py-0 container-fluid unavi";

  },
  methods: {
    branch(url) {
      this.$router.push(url);
    },

    loadStat() {
      if (this.logStat == "error")
        return;
      const url = 'https://urlbank.vpress.asia/log/build.log';
      fetch(url, { cache: "reload" })
        .then(response => response.text())
        .then(data => {
          console.log(data)
          this.isBuild = (data.length > 2);
        })
        .catch(error => {
          this.logStat = "error"
          console.error('ファイルの読み込みエラー:', error);
        });
    },
    polling() {
      this.loadStat();
      if (this.active)
        setTimeout(this.polling, 3000)
    },
    beforeRouteLeave() {
      console.log("leave from factory")
      this.active = false;
    }
  }
}
</script>
