<template>
  <!--
  <sidenav
    :custom_class="this.$store.state.mcolor"
    :class="[
      this.$store.state.isTransparent,
      this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
    ]"
    v-if="this.$store.state.showSidenav"
  /> -->
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''">
    <!-- nav -->
    <navbar :class="[navClasses]" :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : ''"
      :minNav="navbarMinimize" :ver="version" v-if="this.$store.state.showNavbar" />
    <router-view />
  </main>
  <app-footer v-show="this.$store.state.showFooter" />
  <configurator :toggle="toggleConfigurator" :class="[
    this.$store.state.showConfig ? 'show' : '',
    this.$store.state.hideConfigButton ? 'd-none' : '',
  ]" />
  <p style="position: fixed;
                 bottom: 4px;
                 right:90px;
                 color: red;
               ">
    ver.{{ version }}
  </p>
</template>
<script>
//import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations } from "vuex";
import appInfo from "./ver.js";
import { getAPIBASE } from "./Repository.js"; // obuject のインポートは{}の中
export default {
  name: "App",
  components: {
    //Sidenav,
    Configurator,
    Navbar,
    AppFooter,
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
  computed: {
    version() {
      return "0.1." + appInfo.commits + " / " + getAPIBASE();
    },
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
};
</script>

<style>
main.main-content {
  min-height: 800px;
}

div#app,
body {
  background-color: #1a2d39 !important;
  color: rgb(198, 196, 196) !important;
  min-height: 800px;
}
</style>
