<style>
div.result {
  padding: 10px;
  margin: 10px;
  width: 100%;
  border: solid 1px rgb(191, 152, 152);
  border-radius: 20px;
}

div.result input {
  width: 90%;
  border: solid 1px #bbb;
}

div.result select {
  border: solid 1px #bbb;
}

div.result label {
  width: 70px;
}

input.search {
  width: 70%;
  margin-left: 10px;
  border: solid 1px #ccc;
  color: rgb(98, 98, 98);
}

button.btn.search {
  background-color: rgb(203, 192, 177);
  margin-top: 8px;
  color: #fff;
}

button.btn.closeBtn {
  position: absolute;
  top: 60px;
  right: 40px;
  color: #fff;
  background-color: rgb(203, 198, 191);
}

button.preview {
  margin-left: 18px;
  margin-top: 8px;
  color: #eee;
  background-color: rgb(203, 192, 177);
}

span.id {
  background-color: #494d6b;
  border-radius: 10px;
  padding: 0px 9px;
  margin: 0px 6px;
}

p.title {
  background-color: #fff;
  border-bottom: solid 2px rgb(162, 136, 120);
  border-left: solid 32px rgb(162, 136, 120);
  font-size: 1.4em;
  padding: 0px 8px;
  line-height: 32px;
}

table.search td {
  padding: 2px 11px;
  border-bottom: dotted 1px #999;
}

table.search {
  color: #eee;
}

table.search td:nth-child(1) {
  width: 108px;
  border-right: solid 1px #aaa;
}
</style>

<template>
  <button @click="searchExe" class="btn search">検索</button>
  <input id="search" type="text" class="search" v-model="searchStr" />
  <button @click="toggleSearch" class="btn closeBtn">X</button>
  <div v-if="ready" id="result" class="result">
    <p :title="node.created_at">
      id : <span class="id">{{ node.id }} </span>/ {{ node.createdAt }}
      <button @click="preview" class="btn preview">preview</button>
    </p>
    <p class="title">{{ node.desc[0].text }}</p>
    <p>{{ node.url }}</p>
    <p>update at: {{ node.updatedAt }}</p>
    <p>deleted at: {{ node.deletedAt }}</p>
    <p>{{ node.user }}</p>
    <img width="200px" :src="node.imgUrls[0]" />
    <br />
  </div>

  <div v-if="descReady" id="result" class="result">
    <table class="search">
      <tr v-for="(obj, key) in searchAr" :key="key">
        <td @click="previewLoad(key)">{{ getDateS(obj.date) }}</td>
        <td @click="previewLoad(key)">{{ obj.text }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { getHeader, onPreviewID } from "../Repository";
export default {
  name: "VueArSearch",
  props: ["apibase"],
  data() {
    return {
      searchStr: "",
      ready: false,
      descReady: false,
      searchAr: [],
      searchMode: "",
      node: {
        id: -1,
        url: "",
        user: "",
        createdAt: "",
        deletedAt: "",
        imgUrls: [""],
      },
    };
  },
  mounted() {
    //console.log(this.apibase);
  },
  methods: {
    getDateS(dt) {
      return dt.split("T")[0];
    },
    previewLoad(id) {
      //console.log(id);
      onPreviewID(id);
    },
    preview() {
      this.$store.commit("onSetPreviewObj", this.node);
      this.$router.push("/Page");
    },
    toggleSearch() {
      this.ready = !this.ready;
      this.descReady = !this.descReady;
      //console.log(this.descReady);
    },
    searchExe() {
      this.ready = false;
      this.descReady = false;
      this.searchStr = this.searchStr.split("#")[0];
      let apiurl = this.apibase + "/api/v2/articles/urlsearch/";
      this.searchMode = "url";
      if (this.searchStr.indexOf("http") < 0) {
        this.searchMode = "str";
        apiurl = this.apibase + "/api/v2/articles/searchjp/";
      }
      fetch(apiurl, {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify({ url: this.searchStr }),
      })
        .then((res) => {
          if (res.status != 200) {
            console.log(res);
            this.$router.push("/sign-in");
          }
          return res.json();
        })
        .then((data) => {
          if (this.searchMode == "str") {
            this.searchAr = data.data;
            this.descReady = true;
          } else {
            this.node = data.data[0];
            this.ready = true;
          }
          console.log(data);
        });
    },
  },
};
</script>
