import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import SignIn from "@/views/SignIn.vue";
import Urls from "@/views/Urls.vue";
import Pages from "@/views/Pages.vue";
import Daily from "@/views/Daily.vue";
import PagePreview from "@/views/PagePreview.vue";
import PagePreload from "@/views/PagePreload.vue";
import PageEdit from "@/views/PageEdit.vue";
import Factory from "@/views/Factory.vue";
import CrawlerSettings from "@/views/crawlerSettings.vue";
import ReplaceDef from "@/views/ReplaceDef.vue";
import Tweets from "@/views/Tweets.vue";
import VideoMagazines from "@/views/video/Magazines.vue";
import VideoMagazineEdit from "@/views/video/MagazineEdit.vue";
import VideoArticleEdit from "@/views/video/ArticleEdit.vue";

const routes = [
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/",
    name: "/",
    redirect: "/sign-in",
  },

  {
    path: "/factory",
    name: "Factory",
    component: Factory,
  },

  {
    path: "/tweets",
    name: "Tweets",
    component: Tweets,
  },

  {
    path: "/replace",
    name: "ReplaceDef",
    component: ReplaceDef,
  },


  {
    path: "/crawler",
    name: "Crawler Settings",
    component: CrawlerSettings,
  },

  {
    path: "/Home",
    name: "Urls",
    component: Urls,
  },

  {
    path: "/video",
    name: "videoMagazines",
    component: VideoMagazines,
  },
  {
    path: "/video/magazine-edit",
    name: "videoMagazineEdit",
    component: VideoMagazineEdit,
  },
  {
    path: "/video/article-edit",
    name: "videoArticleEdit",
    component: VideoArticleEdit,
  },



  {
    path: "/urls",
    name: "Urls",
    component: Urls,
  },
  {
    path: "/pages",
    name: "Pages",
    component: Pages,
  },
  {
    path: "/daily",
    name: "daily information list",
    component: Daily,
  },


  {
    path: "/page-preload/:id",
    name: "page preload",
    component: PagePreload,
  },


  {
    path: "/page",
    name: "page preview",
    component: PagePreview,
  },

  {
    path: "/page/edit",
    name: "page editor",
    component: PageEdit,
  },
];

const appRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

appRouter.beforeEach((to, from, next) => {
  var token = store.state.token;
  if (token == "") {
    //console.log("no login session");
    if (to.path != "/sign-in") {
      //next("/sign-in");
      appRouter.push("/sign-in");
      //return next();
    } /*
    else {

    }*/
  }
  return next();
});

export default appRouter;
