//import firebase from "firebase/compat/app";
//import { onBeforeUnmount } from "vue";
//import { initializeApp } from "firebase/app";

//import getAuth, setPersistence,browserSessionPersistence,signInWithEmailAndPassword, onAuthStateChanged, from "firebase/auth";

import "firebase/compat/auth";
import store from "./store";
import router from "./router";

//export const APIBASE = "https://ac.vpress.asia/api";
//export const APIBASE = "http://127.0.0.1:8000/api";

export function getAPIBASE() {
  return window.config.APIBASE;
}

/*
console.log(
  " firebase initialization start----------------------------------------"
);

// database v2
const firebaseConfig = {
  apiKey: "AIzaSyDJZwDc5uq_RI0dAzjjq8od2jSEm2Gktho",
  authDomain: "timestamp-v2.firebaseapp.com",
  projectId: "timestamp-v2",
  storageBucket: "timestamp-v2.appspot.com",
  messagingSenderId: "557520168004",
  appId: "1:557520168004:web:35ca701d5c77e0201b0811",
};

// setup firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

console.log("!!! add auth listener --------------------");
export const authListener = onAuthStateChanged(auth, function (user) {
  console.log(user);
});
onBeforeUnmount(() => {
  // clear up listener
  authListener();
});
*/

// util ----------------------------
export function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export function ApiResCheck(res) {
  if (res.status != 200) {
    console.log(res);
    router.push("/sign-in");
  }
}

function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

// FILE ----------------------------------------------------------------------------------

export async function onAddFilePreview(name) {
  store.commit("onAddFilePreview", name);
}

// ファイルをエディターから追加
export async function onFileCreate(file, id, objurl) {
  console.log("onFileCreate");
  console.log("article id " + id);
  console.log(file);
  let url = getAPIBASE() + "/api/v2/articles/image/";
  var fd = new FormData();
  var fileObj = dataURLtoFile(file.fileData, file.fileName)
  fd.append("article", id);
  fd.append("file1", fileObj);
  fd.append("url", objurl);
  fd.append("token", store.state.token);
  return fetch(url, {
    method: "POST",
    headers: getHeaderImg(),
    body: fd,
  }).then((n) => {
    console.log("Image upload ok");
    return n.json();

  }).then((data) => {
    onAddFilePreview(data.filename);
    onUpdateArticleEdit(store.state.preview.id);
    return data;
  });
}

export async function onDeleteImage(id, name, idx) {
  var apiurl = getAPIBASE() + "/api/v2/articles/image/";
  await fetch(apiurl, {
    method: "DELETE",
    headers: getHeader(),
    body: JSON.stringify({ id: id, fname: name }),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      store.commit("onDeleteImg", idx);
      console.log(data);
      return data;
    });
}


export async function saveReplaceJP() {
  console.log(store.state.replaces)
  var rj = {}
  for (var ii = 0; ii < store.state.replaces.length; ii++) {
    rj[ii] = store.state.replaces[ii]
  }

  var data = JSON.stringify({ "data": rj });
  console.log(data)
  console.log(data.toString())
  var apiurl = getAPIBASE() + "/api/v1/sites/replaceJP/";
  return await fetch(apiurl, {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify({ "data": JSON.stringify(store.state.replaces) }),
    //body: data,
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      return data;
    });
  //console.log(res);
}

export async function loadDaily() {
  let jsonPath = "/pub/today.json?" + getTsQuery();
  let url = getAPIBASE() + jsonPath;
  let res = await fetch(url, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  });
  //console.log(res);
  if (res.ok) {
    let data = await res.json();
    store.commit("onDailyJson", data);
    console.log("load daily data ok");
  } else {
    alert("today.json is empty. .");
  }
}

function getTsQuery() {
  var currentDate = new Date();
  return 'timestamp=' + currentDate.getTime();
}

export async function loadReplaceJP() {
  const jsonPath = "/pub/replaceJP.json?" + getTsQuery();
  let url = getAPIBASE() + jsonPath;
  let res = await fetch(url, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  });
  if (res.ok) {
    let data = await res.json();
    //console.log(data)
    store.commit("onReplaceAry", data);
    //console.log("load replace data ok");
  } else {
    alert("replaceJP is empty. created new replaceJP.json.");
  }
}


export async function getSiteData() {
  const jsonPath = "/pub/siteData.vn.json?" + getTsQuery();
  let url = getAPIBASE() + jsonPath;
  let res = await fetch(url, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  });
  //console.log(res);
  if (res.ok) {
    ApiResCheck(res);
    let data = await res.json();
    for (var i in data) {
      if ("skips" in data[i]) continue;
      data[i].skips = [];
      console.log("add skips[] :" + data[i].url);
    }
    store.commit("onSiteData", data);
    //console.log("load sites data ok");
  } else {
    alert("siteData is empty. created new site data.");
    store.commit("onSiteAdd", new SiteSet());
  }
}

export class SiteSet {
  constructor() {
    this.url = "";
    this.dates = [];
    this.targets = [];
    this.captures = [];
    this.stops = [];
    this.img = [];
  }
}

// AUTH ---------------------------------------------------------------------------------------------------
export function authInit() {
  //  setPersistence(auth, browserSessionPersistence);
}
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getHeader() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${store.state.token}`,
  };
}

function getHeaderImg() {
  return {
    Authorization: `Bearer ${store.state.token}`,
  };
}

// -----------------_SEND TO API SERVER ===========>
async function reloadToken(name, pwd) {
  console.log("reloadToken!")
  let url = getAPIBASE() + "/api/signin/";
  var senddata = { mail: name, password: pwd };
  await fetch(url, {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(senddata),
  })
    .then((response) => {
      ApiResCheck(response);
      return response.json();
    })
    .then((data) => {
      console.log("-- token reloaded --")
      store.commit("onAuthChanged", {
        mail: data.result.email,
        token: data.result.idToken,
      });
      setTimeout((arg1, arg2) => { console.log(arg1); reloadToken(arg1, arg2); }, 1000 * 60 * 55, name, pwd);
      return data;
    })
}



// -----------------_SEND TO API SERVER ===========>
export async function loginAPI(name, pwd, sendResponse) {
  console.log("login api");
  let url = getAPIBASE() + "/api/signin/";
  console.log(url);
  var senddata = { mail: name, password: pwd };
  await fetch(url, {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(senddata),
  })
    .then((response) => {
      ApiResCheck(response);
      return response.json();
    })
    .then((data) => {
      store.commit("onAuthChanged", {
        mail: data.result.email,
        token: data.result.idToken,
      });
      sendResponse(data);
      console.log("set reloadToken")
      setTimeout((arg1, arg2) => { console.log(arg1); reloadToken(arg1, arg2); }, 1000 * 60 * 55, name, pwd);
      return data;
    })
    .catch((error) => {
      console.log(error);
      console.log("サーバーが動作していないようです。\n");
      sendResponse({
        status: "error",
        result: { error: "no server" },
      });
    });
  return true;
}

//--------------------------
export function onAuthLogout() {
  /*
  auth.signOut().then(() => {
    store.commit("onLogout");
    appRouter.replace("login");
  });
  */
}
//--------------------------
export function onAuthTest() {
  /*
  var user = auth.currentUser;
  console.log(user);
  if (!user) return false;
  return user.accessToken != "";
  */
}

// Factry ------------------------------------------------------------------------
export async function onFactoryBuild() {
  var url =
    getAPIBASE() +
    `/api/v1/factory/build/`;
  await fetch(url, {
    method: "GET",
    headers: getHeader(),
  })
    .then((res) => {
      store.commit("onFactoryBuild", true);
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data)
      loadSys();
      return data;
    });
}

export async function onFactoryPubAll() {
  var url =
    getAPIBASE() +
    `/api/v1/factory/pub-all/`;
  await fetch(url, {
    method: "GET",
    headers: getHeader(),
  })
    .then((res) => {
      store.commit("onFactoryPubAll", true);
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data)
      loadSys();
      return data;
    });
}

export async function onFactoryDeploy() {
  var url =
    getAPIBASE() +
    `/api/v1/factory/deploy/`;
  await fetch(url, {
    method: "GET",
    headers: getHeader(),
  })
    .then((res) => {
      store.commit("onFactoryDeploy", true);
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data)
      loadSys();
      return data;
    });
}

export async function loadSys() {
  let url = getAPIBASE() + "/api/v1/factory/system/";
  let res = await fetch(url, {
    method: "GET",
    headers: getHeader(),
  });
  //console.log(res);
  if (res.ok) {
    let data = await res.json();
    store.commit("onFactoryStat", data);
    console.log("load factory stat ok");
    console.log(data);
  } else {
    alert("factory stat api error. ");
  }
}

export async function onFactoryPoling() {
  var url =
    getAPIBASE() +
    `/api/v1/factory/poling/`;
  await fetch(url, {
    method: "GET",
    headers: getHeader(),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data)
      if (data.status.indexOf("build") >= 0) {
        store.commit("onFactoryBuild", true);
      }
      else if (data.status.indexOf("deploy") >= 0) {
        store.commit("onFactoryDeploy", true);
      } else if (data.status.indexOf("puball") >= 0) {
        store.commit("onFactoryPubAll", true);
      } else {
        store.commit("onFactoryBuild", false);
        store.commit("onFactoryDeploy", false);
        store.commit("onFactoryPubAll", false);
      }
      return data;
    });
}




// Daily ------------------------------------------------------------------------
export async function onGetDaily() {
  //const limit = 31;
  //var skip = limit * page;
  var url =
    getAPIBASE() +
    ///`/api/v1/daily/?skip=${skip}&limit=${limit}`;
    `/api/v1/daily/`;
  await fetch(url, {
    method: "GET",
    headers: getHeader(),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data)
      store.commit("onDaily", data);
      return data;
    });
}


// URLS ------------------------------------------------------------------------
export async function onGetUrls(page, captured, rejected, err) {
  const limit = 25;
  var skip = limit * page;
  var url =
    getAPIBASE() +
    `/api/v1/urls/?skip=${skip}&limit=${limit}&captured=${captured}&rejected=${rejected}&err=${err}`;
  //  if (noCaptured) url = url + "&captured=0";
  await fetch(url, {
    method: "GET",
    headers: getHeader(),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      //console.log(data);
      let cdata = [];
      for (var i in data) {
        if (
          data[i].url.indexOf("http") >= 0 &&
          data[i].url.indexOf("#") < 0 &&
          data[i].url.indexOf("breakfasti-tuoi-") < 0
        )
          cdata.push(data[i]);
      }
      store.commit("onUrls", cdata);
      return data;
    });
}

export async function onCountup(id = id) {
  var apiurl = getAPIBASE() + "/api/v1/urls/try/" + id;
  console.log(apiurl);
  await fetch(apiurl, {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify({ id: id }),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
    });
  //console.log(res);
}

export async function onDelURL(id = id) {
  var apiurl = getAPIBASE() + "/api/v1/urls/reject/";
  console.log(apiurl);
  await fetch(apiurl, {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify({ id: id }),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      store.commit("onReject", id);

    });
  //console.log(res);
}

export async function onResetPage() {
  store.commit("onArPageReset");
}

export async function onNextPage() {
  store.commit("onArPageNext");
}

export async function onPrevPage() {
  store.commit("onArPagePrev");
  //onGetPages(store.state.arPage, limit);
}

export async function onGetPages(
  page,
  limit = 25,
  mode = "all",
  start = "",
  end = "",
  group = ""
) {
  var skip = limit * page;
  var url =
    getAPIBASE() +
    `/api/v2/articles/?skip=${skip}&limit=${limit}&mode=${mode}&start=${start}&end=${end}&group=${group}`;
  //console.log(url);
  //console.log(getHeader());
  await fetch(url, {
    method: "GET",
    headers: getHeader(),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      //console.log(data);
      store.commit("onPages", data);
      console.log(data)
      return data;
    });
  //console.log(res);
}

export function onPreview(idx) {
  store.commit("onSetPreview", idx);
}

// div => p
// span not beside image => p
function cleanDescTag(desc) {
  console.log(desc)
  if (!desc)
    return
  if (desc.length == 0)
    return
  if (desc[0].tag == "h2")
    desc[0].tag = "h1";
  for (var ii in desc) {

    // img 直後のspan 以外は p
    if (ii > 0 && (desc[ii].tag == "span")) {
      if (desc[ii - 1].tag != "img")
        desc[ii].tag = "p"
    }
    if (desc[ii].tag == "div")
      desc[ii].tag = "p"


    /*
    let next = (parseInt(ii) + 1).toString();
    let prev = (parseInt(ii) - 1).toString();
    let isNext = next < desc.length;
    let isPrev = prev >= 0;
    if (desc[ii].tag == "img")
      continue;
    if (desc[ii].tag == "p")
      continue;

    if (isNext && desc[ii].tag == "div" && (desc[next].tag == "img"))
      desc[ii].tag = "span";
    else if (isPrev && desc[ii].tag == "div" && desc[prev].tag == "img")
      desc[ii].tag = "span";
    else if (isPrev && desc[prev].tag == "img" && desc[ii].text.indexOf("写真：" > 0))
      desc[ii].tag = "span";
    else if (ii > 0 && isNext && desc[next].tag == "img" && desc[ii].text.indexOf("写真：" > 0)) {
      desc[ii].tag = "p"
      if (!desc[ii].classes.includes("image-head"))
        desc[ii].classes.push("image-head");
    }
    else if (isPrev && isNext && desc[ii].tag == "span" && desc[prev].tag != "img" && desc[next].tag != "img")
      desc[ii].tag = "p";
    else if (desc[ii].tag == "div") {
      desc[ii].tag = "p";
    }
    */
  }
  //console.log(desc)
  return desc
}

export async function onPreviewID(id) {
  var url = getAPIBASE() + `/api/v2/articles/${id}/`;
  await fetch(url, {
    method: "GET",
    headers: getHeader(),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      if (data.desc === undefined)
        data.desc = []
      if (data.descJP === undefined)
        data.descJP = []

      data.desc = cleanDescTag(data.desc)
      data.descJP = cleanDescTag(data.descJP)
      console.log(data)
      store.commit("onSetPreviewObj", data);
      router.push("/Page");
      return data;
    });
}


export async function onSummarizeAPI(id) {
  var url = getAPIBASE() + `/api/v2/articles/sum_desc/${id}/`;
  console.log(url);
  await fetch(url, {
    method: "GET",
    headers: getHeader(),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      return data;
    });
}



export async function saveSiteData(group, data) {
  store.commit("onSiteData", data);
  var apiurl = getAPIBASE() + "/api/v1/sites/";
  console.log(apiurl);
  return await fetch(apiurl, {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify({ name: group, data: data }),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      //console.log(data);
      return data;
    });
  //console.log(res);
}

export async function onUpdateDesc(idx, text) {
  store.commit("onUpdateDesc", {
    idx: idx,
    text: text,
  });
}

export async function onUpdatePublished() {
  let id = store.state.preview._id;
  const elm = document.querySelector("#err-msg");
  elm.textContent = "";

  let objUrl = store.state.preview.url;
  let pub = !store.state.preview.published;
  let data = { id: id, url: objUrl, published: pub };
  var url = getAPIBASE() + `/api/v2/articles/` + id + "/";
  await fetch(url, {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then((res) => {
      ApiResCheck(res);
      store.commit("onEditPublish");
      return res.json();
    })
    .then((data) => {
      console.log(data);
      if (data.status == "error") {
        console.log("update published error")
        const elm = document.querySelector("#err-msg");
        elm.textContent = data.msg;
      }
      //store.commit("onPages", data);
      return data;
    });
}

export async function onUnlockAC() {
  let data = { "id": store.state.preview._id }
  console.log(data)
  var url = getAPIBASE() + `/api/v2/articles/unlock`;
  await fetch(url, {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then((res) => {
      //ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      //store.commit("onPages", data);
      if (data.status == "ok") {
        store.commit("onEditPublish");
      }
      return data;
    });
}

export function getDescriptionText() {
  var desc = "description:";
  for (var ii = 1; ii < store.state.preview.desc.length; ii++) {
    let dd = store.state.preview.desc[ii];
    if (dd.tag == "p") {
      desc = desc + dd.text;
    }
  }
  return desc;

}

export async function onSummarize(st, callback) {
  var cmd1 =
    " 'description:' で始まる文章を280文字以下に要約してください。  要約した文章は以下の処理をしてください\n \
  処理1: 要約した文章を「ですます調」の日本語に翻訳してください。\n \
  処理2: VNDもしくはドンが付く数値は通貨ベトナムドンなので日本円に換算して記述してください。\n \
  処理3: 返答は翻訳した文章だけとします";

  /*
var desc = "description:";
for (var ii = 1; ii < store.state.preview.desc.length; ii++) {
  let dd = store.state.preview.desc[ii];
  if (dd.tag == "p") {
    desc = desc + dd.text;
  }
}*/
  const elm = document.querySelector("#err-msg");
  elm.textContent = "";

  let desc = getDescriptionText()
  let data = { "id": store.state.preview._id, "com1": cmd1, "desc": desc }
  var url = getAPIBASE() + `/api/v1/openai/`;
  await fetch(url, {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then((res) => {
      //ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      //store.commit("onPages", data);
      if (data.status == "ok") {
        store.commit("onUpdateSum", data.answer)
        callback(st);
      }
      return data;
    });
}


export async function onUpdateArticleEdit() {
  let _id = store.state.preview._id;
  let clone = JSON.parse(JSON.stringify(store.state.preview));
  const elm = document.querySelector("#err-msg");
  elm.textContent = "";
  //console.log(_id)
  //clone.id = clone._id;
  var url = getAPIBASE() + `/api/v2/articles/` + _id + "/";
  await fetch(url, {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(clone),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      if (data.status == "error") {
        console.log("update published error")
        const elm = document.querySelector("#err-msg");
        elm.textContent = data.msg;
      }

      return data;
    });
}

export async function DeleteArticles(ary) {
  if (ary.length == 0)
    return;

  var delObjs = [];
  ary.forEach(function (v) {
    console.log(store.state.pages[parseInt(v)]);
    delObjs.push(store.state.pages[parseInt(v)].id);
  });

  //clone.id = clone._id;
  var url = getAPIBASE() + `/api/v2/articles/`;
  await fetch(url, {
    method: "DELETE",
    headers: getHeader(),
    body: JSON.stringify({ ids: delObjs }),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      return data;
    });
}

export async function onNewArticle() {

  //clone.id = clone._id;
  var url = getAPIBASE() + `/api/v2/articles/`;
  await fetch(url, {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify({}),  //new 
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      store.commit("onNewArticle", data.data)
      router.push("/Page");
      return data;
    });
}

export async function onTweetReservations() {
  var dt = new Date().getTime();

  var url = getAPIBASE() + `/api/v1/tweet/reservation?dt=${dt}`;
  await fetch(url, {
    method: "GET",
    headers: getHeader(),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      store.commit("onTweetReservations", data.data)
      //router.push("/Page");
      return data;
    });
}

export async function onTweetAPI(id, date, text, isLine) {
  var url = getAPIBASE() + `/api/v1/tweet/`;
  var tpost = text + "\n" + "https://www.vpress.asia/article/" + id + "/";
  //console.log(tpost)
  /*
  if (tpost.length > 190) {
    if (!confirm("文字数(190)オーバーです\n\nこのまま実行しますか？" + tpost + "\n\n" + tpost.length + "文字"))
      return false;
  }*/
  await fetch(url, {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify({ tid: id, date: new Date(date).toISOString(), text: tpost, "isLINE": isLine }),  //new 
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      //alert("tweet j予約しました")
      let elm = document.getElementById("tweet-result");
      elm.textContent = "tweetしました。"
      //store.commit("onNewArticle", data.data)
      //router.push("/Page");
      return data;
    });
  return true;
}

export async function onGetTweets() {
  var url = getAPIBASE() + `/api/v1/tweet/?limit=100`;
  await fetch(url, {
    method: "GET",
    headers: getHeader(),
    //body: JSON.stringify({}),  //new 
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      store.commit("onTweets", data)
      //router.push("/Page");
      return data;
    });
}

export async function OnDeleteTweets(ary) {
  if (ary.length == 0)
    return;
  var delObjs = [];
  ary.forEach(function (v) {
    console.log(store.state.tweets[parseInt(v)]);
    delObjs.push(store.state.tweets[parseInt(v)]._id);
  });

  //clone.id = clone._id;
  var url = getAPIBASE() + `/api/v1/tweet/`;
  await fetch(url, {
    method: "DELETE",
    headers: getHeader(),
    body: JSON.stringify({ ids: delObjs }),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      onGetTweets()
      return data;
    });
}



// get article by URL
export async function onArticleByURL(objUrl) {
  var url = getAPIBASE() + `/api/v2/articles/getbyurl/`;
  //let url = getAPIBASE() + "/api/v2/articles/urlsearch/";
  var senddata = { url: objUrl };

  console.log(objUrl)

  let res = await fetch(url, {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(senddata),
  })
    .then((res) => {
      console.log(res)
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      return data;
    });

  return await res;
}

