<template template>
  <bank-navi :user="user"></bank-navi>
  <!-- Modal VND計算 -->
  <div class="modal fade" id="vndModal" tabindex="-1" role="dialog" aria-labelledby="vndModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="vndModalLabel">VND計算</h5>
          <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>大きな数字の入力ヘルパー。億、千万、VNDは合算されます。</p>
          <label for="scales">億VND</label>
          <input @change="vndUpdate()" v-model="okuVND" type="input" />
          <br />

          <label for="scales">千万VND</label>
          <input @change="vndUpdate()" v-model="senmanVND" type="input" />
          <br />

          <label for="scales">VND</label>
          <input @change="vndUpdate()" v-model="suVND" type="input" />
          <br />

          <label for="scales">合算後VND</label>
          <input @change="vndUpdate()" v-model="simpleVND" type="input" />
          <br />
          <br />

          <p>結果</p>
          <label for="scales">円レート</label>
          <input @change="vndUpdate()" v-model="yenRate" type="input" />
          <br />

          <label for="scales">円額</label>
          <input v-model="vndYen" type="input" />
          <p>{{ simpleVND }}ドン約{{ vndYen.toLocaleString() }}円</p>
          <br />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">
            キャンセル
          </button>
          <button @click="copyVnd()" type="button" class="btn bg-gradient-primary">
            コピー
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal1 -->
  <div class="modal fade" id="chkModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">文言チェク</h5>

          <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="text-check">
            <tr v-for="oo in checker" :key="oo.name">
              <td>
                <input v-model="oo.flg" type="checkbox" checked />
                <label for="scales">{{ oo.name }} => {{ oo.ans }}</label>
              </td>
              <td>{{ oo.num }}</td>
              <td>{{ oo.res }}</td>
            </tr>
          </table>
          ※デフォルトでチェックがついているものだけ自動変換できます
        </div>
        <div class="modal-footer">
          <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">
            キャンセル
          </button>
          <button @click="strCheck()" type="button" class="btn bg-gradient-primary">
            check
          </button>

          <button @click="convert()" type="button" class="btn bg-gradient-primary">
            変換
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="imageBox">
    <VueImageBase64 :maxFileSize="19097152" :thumbnailSize="1280" :drop="true" capture="environment" :multiple="true"
      @handleChange="handleChange" />

    <ul>
      <li v-for="(item, index) in getImages" :key="index">
        <div class="img-body" v-if="!item.deleted">
          <p class="img-name">
            {{ item.name }}
            <button btn @click="delNewsImage(index)" style="background-color: #f77">
              X
            </button>
          </p>
          <img :id="index" :src="getImgUrl(item)" height="80" draggable="true" v-on:dragstart="DGstart"
            v-on:dragend="DGdropEnd" />
        </div>
      </li>
    </ul>
  </div>

  <div class="container-fluid">
    <button @click="back" class="btn bg-gradient-light btnBack">Back</button>
    <h2>News:{{ obj.date }}</h2>
    <div class="card2">
      <div class="row">
        <div class="row my-0">
          <div class="col-lg-9 col-md-9 mb-md-0 mb-0">
            <form role="form" class="text-start">
              <input type="date" v-model="date" onkeydown="return false" @change="updateDate" />
            </form>

            <span class="date">Last updater: {{ obj.lastUpdater }}</span>
            <span class="date">
              Created: {{ createdAt }} / Updated: {{ updatedAt }}
            </span>

            <form role="form" class="text-start">
              <div class="form-group">
                <div class="input-group">
                  <span class="input-group-text">本文</span>
                  <textarea style="min-height: 320px" @change="descUpdate" v-model="description" class="form-control"
                    id="desc" aria-label="With textarea"></textarea>
                </div>
              </div>
              <!--
            <vsud-switch id="rememberMe" name="rememberMe" checked>
              Switch
            </vsud-switch> 
            -->
            </form>
          </div>
        </div>
        <div class="row my-0">
          <div class="col-lg-3 col-md-3 mb-md-0 mb-0">
            <button type="button" class="btn bg-gradient-secondary" data-bs-toggle="modal" data-bs-target="#vndModal">
              VNDﾍﾙﾊﾟｰ
            </button>
            <button type="button" @click="strCheck()" class="btn bg-gradient-primary" data-bs-toggle="modal"
              data-bs-target="#chkModal">
              文言チェック
            </button>
          </div>
          <div class="memo">
            <label>MEMO 記事のURL</label><br />
            <textarea class="memo" v-model="memo" @change="updateMemo" />
          </div>
        </div>
      </div>
      <div class="row my-0">
        <div class="col-lg-9 col-md-9 mb-md-0 mb-0">
          <div class="ztable-responsive">
            <table class="align-items-center news-table">
              <thead>
                <tr>
                  <th>text</th>
                  <th>len</th>
                  <th>pose</th>
                  <th colspan="2" v-on:ondrop="dropImg">Pictures</th>
                  <th>x</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(oo, index) in lineList" :key="index">
                  <td class="news-text">
                    {{ oo.text }}
                  </td>
                  <td>
                    {{ oo.text.length }}
                  </td>
                  <td>
                    {{ oo.mcAnim }}
                    <select v-model="oo.mcAnim" @change="poseChange($event, index)">
                      <option v-for="option in poseTbl" :value="option.code" :key="option.code">
                        {{ option.lbl }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <img :src="getMcPic(index)" width="40" />
                  </td>
                  <td v-if="oo.pic" class="news-pic" :id="picId(oo.pic.name)">
                    <div class="dzone">
                      <span>{{ oo.pic.name }}</span>
                      <img :id="zoneId(index)" :src="getImgUrl(oo.pic)" class="dropimage" @drop.prevent="DGdrop"
                        @dragover="DGover" @dragenter="checkDrop" @dragleave="DGleave" />
                    </div>
                  </td>
                  <td class="pic-del">
                    <button @click="delPic(index)" class="btn bg-gradient-danger delbtn">
                      X
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="text-left">
        <vsud-button class="my-2 mb-1" @click="save" variant="gradient" color="success">
          Save
        </vsud-button>
      </div>
    </div>
  </div>
</template>


<style src="./ArticleEdit.css"></style>
<script>
import BankNavi from "@/components/UrlbankNavi.vue";
import VsudButton from "@/components/VsudButton.vue";
import VueImageBase64 from "vuejs-image-base64";
import {
  onVmArticleUpdate,
  onMagazineUpdate,
  onArticleDateUpdate,
} from "../../RepoVideo";



import {
  onDeleteImage,
  onFileCreate,
  getAPIBASE,
} from "../../Repository";

function chkNum(org, str) {
  var res = org.split(str);
  return res.length - 1;
}

function chkSimNum(org, str1, str2) {
  var res1 = org.split(str1);
  var num1 = res1.length - 1;
  var res2 = org.split(str2);
  var num2 = res2.length - 1;
  return num1 - num2;
  //  return (org.match("/" + str + "/g") || []).length;
}

export default {
  name: "dashboard-default",
  components: {
    VsudButton,
    BankNavi,
    VueImageBase64,
  },

  data() {
    return {
      apibase: getAPIBASE(),
      obj: {},
      date: "",
      newsDate: "",
      okuVND: 0,
      senmanVND: 0,
      suVND: 0,
      simpleVND: 0,
      memo: "",
      yenRate: 0.0057,
      vndYen: 0,
      strVNDYen: "",
      description: "", // one text
      ctx: [{ text: "text", picId: 0, pic: "" }], // table
      images: { data: [] },
      errors: [],
      pictures: [],
      dialogState: false,
      isShow: false,
      isImportShow: false,
      poseTbl: [
        { lbl: "none", code: "" },
        { lbl: "Hello", code: "02" },
        { lbl: "Bow", code: "03" },
        { lbl: "Yummy", code: "04" },
        { lbl: "Sad", code: "05" },
        { lbl: "Angry", code: "06" },
        { lbl: "Bad", code: "07" },
        { lbl: "Wow", code: "08" },
        { lbl: "Love", code: "09" },
        { lbl: "Good1", code: "09b" },
        { lbl: "Good2", code: "09c" },
      ],
      // checker
      checker: [
        {
          flg: false,
          name: "VND",
          ans: "ドン約...円",
          res: "",
          num: 99,
          chk: chkNum,
          exe: null,
        },
        {
          flg: true,
          name: "州",
          ans: "省",
          res: "",
          num: 99,
          chk: chkNum,
          //exe: exeReplace,
          exe: this.replaceStr,
        },
        {
          flg: true,
          name: "である。",
          ans: "です。",
          res: "",
          num: 99,
          chk: chkNum,
          exe: this.replaceStr,
        },
        {
          flg: true,
          name: "する。",
          ans: "します。",
          res: "",
          num: 99,
          chk: chkNum,
          exe: this.replaceStr,
        },
        {
          flg: true,
          name: "された。",
          ans: "されました。",
          res: "",
          num: 99,
          chk: chkNum,
          exe: this.replaceStr,
        },
        {
          flg: true,
          name: "に位置し",
          ans: "にあり",
          res: "",
          num: 99,
          chk: chkNum,
          exe: this.replaceStr,
        },
        {
          flg: false,
          name: "困難な",
          ans: "むつかしい",
          res: "",
          num: 99,
          chk: chkNum,
          exe: null,
        },
        {
          flg: false,
          name: "引きつける",
          ans: "訪れる?",
          res: "",
          num: 99,
          chk: chkNum,
          exe: null,
        },
        {
          flg: true,
          name: "でおり",
          ans: "でいて",
          res: "",
          num: 99,
          chk: chkNum,
          exe: this.replaceStr,
        },
        {
          flg: true,
          name: "であり",
          ans: "で",
          res: "",
          num: 99,
          chk: chkNum,
          exe: this.replaceStr,
        },
        {
          flg: false,
          name: "した。",
          ans: "しました。",
          res: "",
          num: 99,
          chk: chkSimNum,
          exe: null, //this.replaceStr,
        },
      ],
    };
  },
  computed: {
    createdAt() {
      var d = new Date(this.obj.createdAt);
      //return d.toString();
      return d.toLocaleString("ja");
    },
    updatedAt() {
      var d = new Date(this.obj.updatedAt);
      return d.toLocaleString("ja");
      //return d.toString();
    },
    lineList() {
      console.log(this.$store.state.vm.curAr);
      console.log(this.$store.state.vm.curAr.lines);

      return this.$store.state.vm.curAr.lines;
    },
    user() {
      return this.$store.state.mail;
    },

    getImages() {
      return this.$store.state.vm.curAr.images;
    },
    nodes() {
      return this.$store.state.vm.curAr;
    },
    lines() {
      return this.$store.state.vm.curAr.lines;
    },
  },
  mounted() {
    console.log("mounted----------------");
    console.log(this.$store.state.vm.curAr);
    this.date = this.$store.state.vm.curAr.date;
    this.memo = this.$store.state.vm.curAr.urls;
    this.description = this.$store.state.vm.curAr.text;
    this.obj = this.$store.state.vm.curAr;
  },
  methods: {
    getNewsImg(obj) {
      if (obj.images)
        return obj.images[0];

    },
    getDesc(page) {
      if (page.descJP && page.descJP.length > 0) return page.descJP;
      return page.desc;
    },

    updateDate() {
      this.obj.date = this.date
      onArticleDateUpdate(this.date);
    },
    getMcPic(idx) {
      return "/mc/s" + this.$store.state.vm.curAr.lines[idx].mcAnim + ".png";
    },

    poseChange(ev, idx) {
      this.$store.commit("onVmArticlePoseUpdate", {
        idx: idx,
        pose: ev.target.value,
      });
      onVmArticleUpdate(this.$store.state.vm.curAr); // update server
    },
    strCheck() {
      console.log("check");
      for (var oo in this.checker) {
        var obj = this.checker[oo];
        obj.num = obj.chk(this.description, obj.name, obj.ans);
      }
    },
    convert() {
      console.log("convert");
      for (var oo in this.checker) {
        var obj = this.checker[oo];
        if (obj.exe != null) {
          //console.log( obj.exe(this.description, obj.name, obj.ans));
          this.description = obj.exe(this.description, obj.name, obj.ans);
        }
      }
      this.strCheck();
    },

    getImgUrl(img) {
      console.log(img)
      return this.apibase + `/img/thumb/${img.url}`;
    },

    delNewsImage(id) {
      if (id < 0) return;
      if ([] == this.$store.state.vm.curAr.images) return null;
      if (this.$store.state.curAr.vm.images.length <= id) return null;
      if (confirm("Are you sure deleting this image? (id=" + id + ")"))
        onDeleteImage(id);
    },
    delPic(lineIdx) {
      //var pic = { name: "", url: "" };
      this.$store.commit("onVmArticlePicDelete", lineIdx);
    },
    getPicName(id) {
      if (id == null) return null;
      if (id === undefined) return null;
      if (id < 0) return null;
      if ([] == this.$store.state.vm.curAr.images) return null;
      if (this.$store.state.vm.curAr.images.length <= id) return null;
      return this.$store.state.vm.curAr.images[id].name;
    },

    getPic(id) {
      if (id == null) return null;
      if (id === undefined) return null;
      if (id < 0) return null;
      if ([] == this.$store.state.vm.curAr.images) return null;
      if (this.$store.state.curAr.vm.images.length <= id) return null;
      console.log("get pic " + id);
      return this.$store.state.vm.curAr.images[id].url;
    },
    save() {
      onVmArticleUpdate(this.$store.state.vm.curAr);
      onMagazineUpdate(this.$store.state.vm.curMag);
      this.$router.push("/video/magazine-edit");
    },
    back() {
      this.$router.push("/video/magazine-edit");
    },
    imgId(num) {
      return "img" + num.toString();
    },
    zoneId(num) {
      return "zone" + num.toString();
    },

    picId(num) {
      return "pic" + num; //num.toString();
    },
    checkDrop: function (ev) {
      ev.preventDefault();
    },
    skipDrop: function (ev) {
      ev.preventDefault();
    },

    // drag obj
    DGstart: function (ev) {
      console.log("dropStart id=" + ev.target.id);
      ev.dataTransfer.setData("text/plain", ev.target.id);
    },
    // drag obj
    DGdropEnd: function (ev) {
      ev.preventDefault();
      console.log("dropEnd");
    },
    // drop target
    DGover: function (ev) {
      ev.preventDefault();
      var tag = document.getElementById(ev.target.id);
      if (tag) tag.classList.add("drop-over");
    },
    // drop target
    DGleave: function (ev) {
      ev.preventDefault();
      var tag = document.getElementById(ev.target.id);
      if (tag) tag.classList.remove("drop-over");
    },
    // drop target
    DGdrop: function (ev) {
      ev.preventDefault();
      console.log("drop -----------");
      console.log(ev);
      if (ev.target.id.indexOf("zone") < 0) return;
      var lineIdx = parseInt(ev.target.id.split("zone")[1]);
      var pic = parseInt(ev.dataTransfer.getData("text"));
      console.log("drop = " + pic);
      if (isNaN(pic)) return;
      if (pic == null) return;
      if (pic >= this.$store.state.vm.curAr.images.length) return;
      //console.log(lineIdx);
      this.$store.commit("onVmArticlePicUpdate", { lineIdx, pic });
      onVmArticleUpdate(this.$store.state.vm.curAr);
      onMagazineUpdate(this.$store.state.vm.curMag);
    },

    dropImg: function (ev) {
      ev.preventDefault();
      console.log("dropped ${ev}");
    },
    updateMemo: function () {
      this.$store.commit("onVmArticleMemoUpdate", this.memo); // update state
      console.log(this.$store.state.vm.curMag);
      onVmArticleUpdate(this.$store.state.vm.curAr); // update server
      onMagazineUpdate(this.$store.state.vm.curMag);
    },
    vndUpdate: function () {
      var vnd = this.okuVND * 100000000;
      vnd += this.senmanVND * 10000000;
      vnd += this.suVND * 1;
      this.simpleVND = vnd;
      this.vndYen = vnd * this.yenRate;
      this.strVNDYen = vnd + "ドン約" + this.vndYen.toLocaleString() + "円";
    },
    descUpdate: function () {
      this.strCheck();

      var desc = this.description.replace(/^\s*[\r\n]/gm, "");
      desc = desc.replace(/ /g, "");
      //desc = desc.replace(/^\n/gm, "");
      this.description = desc;
      this.$store.commit("onVmArticleTextUpdate", desc); // update state
      this.$store.state.vm.curAr.updateLinesByText(desc);
      /*
      //lines
      var arr = desc.split(/\r\n|\n/);
      // clean
      var dList = [];
      for (var tx in arr) {
        if (arr[tx].length < 2) continue;
        dList.push(arr[tx]);
      }
      console.log(this.$store.state.vm.curAr)
      console.log(this.$store.state.vm.curAr.constructor.name)
      this.$store.state.vm.curAr.updateAllLines(dList);
      this.$store.commit("onVmArticleLineUpdate", dList); // update state
      */
      onVmArticleUpdate(this.$store.state.vm.curAr); // update server
      onMagazineUpdate(this.$store.state.vm.curMag);
    },
    replaceStr: function (org, str, ans) {
      let result = org.replace(new RegExp(str, "g"), ans);
      return result;
    },
    copyVnd() {
      navigator.clipboard.writeText(this.strVNDYen);
    },
    handleChange: function (data) {
      if (data.result) {
        onFileCreate(data, this.$store.state.vm.curAr.id);
      } else {
        this.errors = data.messages;
      }
    },
    //  return (org.match("/" + str + "/g") || []).length;
  },
};
</script>
