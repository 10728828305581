import "gitart-vue-dialog/dist/style.css";

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import appRouter from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/css/urlbank.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import { plugin as dialogPlugin } from "gitart-vue-dialog";
import appConfig from "./config.json";
import "gitart-vue-dialog/dist/style.css";
import { GDialog } from "gitart-vue-dialog";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';


const app = createApp(App);

Date.prototype.addHours = function (h) {
  this.setHours(this.getHours() + h);
  return this;
}
/*
console.log(
  `booting Vue app: BaseURL=${process.env.BASE_URL}  mode=${process.env.URLBANK} `
);
*/

//var isProd = 1 == 0;
var serverMode = "prod";

console.log(process.env)

if (process.env.VUE_APP_LOCALSV && process.env.VUE_APP_LOCALSV == "1") {
  serverMode = "dev";
}
console.log(serverMode)


if (serverMode == "dev") {
  window.config = appConfig.devel;
  app.config.globalProperties.config = appConfig.prod;
} else {
  window.config = appConfig.prod;
  app.config.globalProperties.config = appConfig.prod;
}

app.use(store);
app.use(appRouter);
app.use(SoftUIDashboard);
app.use(dialogPlugin);
app.component("VueDatePicker", VueDatePicker);
app.component("GDialog", GDialog);
app.mount("#app");
