<style>
div.result {
  padding: 10px;
  margin: 10px;
  width: 100%;
  border: solid 1px rgb(191, 152, 152);
  border-radius: 20px;
}
div.result input {
  width: 90%;
  border: solid 1px #bbb;
}
div.result select {
  border: solid 1px #bbb;
}
div.result label {
  width: 70px;
}

input.search {
  width: 70%;
  margin-left: 10px;
  border: solid 1px #ccc;
  color: rgb(98, 98, 98);
}

button.btn.search {
  background-color: rgb(203, 192, 177);
  margin-top: 8px;
  color: #fff;
}

div.result button.btn.closeBtn {
  position: absolute;
  top: 70px;
  right: 40px;
  color: #fff;
  background-color: rgb(203, 198, 191);
}
</style>

<template>
  <button @click="searchExe" class="btn search">検索</button>
  <input id="search" type="text" class="search" v-model="searchStr" />
  <div v-if="ready" id="result" class="result">
    <p :title="node.created_at">id: {{ node.id }}</p>
    <button @click="closeSearch" class="btn closeBtn">X</button>
    <label for="url">URL: </label>
    <input id="url" type="text" v-model="node.url" readonly /><br />
    <label for="captured">captured: </label>
    <select v-model="node.captured">
      <option>0</option>
      <option>1</option></select
    ><br />
    <label for="count">count: </label>
    <span id="count"> {{ node.count }} </span>
    <br />

    <label for="rejected">rejected: </label>
    <select v-model="node.rejected">
      <option>0</option>
      <option>1</option></select
    ><br />
    <label for="err_stat">err_stat: </label>
    <select v-model="node.err_stat">
      <option>0</option>
      <option>1</option>
      <option>2</option>
    </select>
    <br />
  </div>
</template>

<script>
import { getHeader } from "../Repository";
export default {
  name: "VueSearch",
  props: ["apibase"],
  data() {
    return {
      searchStr: "",
      ready: false,
      node: {
        id: -1,
        url: "",
        captured: false,
        rejected: false,
        err_stat: 0,
      },
    };
  },
  mounted() {
    console.log(this.apibase);
  },
  methods: {
    closeSearch() {
      this.ready = false;
    },
    searchExe() {
      this.ready = false;
      this.searchStr = this.searchStr.split("#")[0];
      console.log("search! " + this.searchStr);
      var apiurl = this.apibase + "/api/v1/urls/search/";
      fetch(apiurl, {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify({ url: this.searchStr }),
      })
        .then((res) => {
          if (res.status != 200) {
            console.log(res);
            this.$router.push("/sign-in");
          }
          return res.json();
        })
        .then((data) => {
          console.log(data);
          this.node = data.data;
          this.ready = true;
        });
    },
  },
};
</script>
