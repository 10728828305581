<style>
.tagary p {
  margin: 0px;
  color: #eee;
  font-size: 1.2em;
  font-weight: bold;
}

.tagary {
  width: 98%;
  margin: 8px;
  padding: 4px;
  border: solid 1px #46566a;
  border-radius: 4px;
  background-color: rgb(43, 51, 57);
}

.name button {
  font-size: 0.8em;
  padding: 0px 7px;
  line-height: 20px;
  margin: 0;
  border-radius: 4px;
  background-color: rgb(33, 98, 164);
}
</style>
<template>
  <div class="tagary" :key="render">
    <p class="name">
      {{ name }}
      <button @click="addNode">Add</button>
    </p>
    <template v-for="(node, index) in edAry" :key="index">
      <tag-input @emchange="emitChange" @emdelete="emitDelete" :dataIn="node" :index="index"></tag-input>
    </template>
  </div>
</template>

<script>
import TagInput from "@/components/TagInput.vue";
export default {
  name: "TagInputAry",
  components: {
    TagInput,
  },
  props: ["name", "ary"],
  data() {
    return {
      edAry: [],
      render: 0,
    };
  },
  mounted() {
    this.edAry = this.ary;
    //console.log(this.edAry);
  },
  methods: {
    emitDelete(idx) {
      if (confirm("削除していいの？ id=" + idx)) {
        let newAry = [];
        for (var ii = 0; ii < this.edAry.length; ii++) {
          if (ii != parseInt(idx)) newAry.push(this.edAry[ii]);
        }
        this.edAry = newAry;
        this.$emit("emchange", { name: this.name, ary: this.edAry });
        this.render++;
      }
    },
    emitChange(obj) {
      //console.log("recieve emit");
      this.edAry[obj.idx] = obj.node;
      this.$emit("emchange", { name: this.name, ary: this.edAry });
    },
    addNode() {
      this.edAry.push({ tag: "", class: "" });
      this.$emit("emchange", { name: this.name, ary: this.edAry });
    },
  },
};
</script>
