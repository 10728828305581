<style>
.selGroup label,
.selSite label {
  font-size: 1.2em;
}

.selSite select,
.selGroup select,
.selSite input {
  font-size: 1.1em;
  margin: 10px 4px;
  width: 280px;
  height: 30px;
  font-size: 24pxh;
}

.selGroup img {
  height: 20px;
}

.settings input,
.settings select {
  background-color: #ddd;
}

.url {
  width: 100%;
  margin: 8px;
  padding: 4px;
  border: solid 1px rgb(148, 159, 168);
  border-radius: 4px;
}

input.url {
  width: 98%;
}

input:hover {
  background-color: rgb(140, 132, 132);
}

img.site-url {
  margin-left: 20px;
  height: 24px;
}

.selSite li {
  font-size: 1.3em;
}

.selSite li:hover {
  background-color: rgb(224, 223, 177);
}

.selSite li img {
  width: 110px;
  padding: 8px;
}

.dlgwrapper {
  color: #000;
  padding: 10px;
}

.dlgwrapper .content {
  padding: 20px;
}

button.dlg {
  margin-top: 10px;
  margin-left: 30px;
  width: 70px;
  color: #333;
  background-color: #98d1c9;
}

div.site-frame {
  position: relative;
}

.selGroup label {
  color: #eee;
}

button.add-group {
  width: 120px;
  margin-top: 10px;
  margin-left: 30px;
  font-size: 1.2em;
  color: #333;
  background-color: #9fddc8;
}

button.save {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 80px;
  font-size: 1.2em;
  color: #333;
  background-color: #dd9fd4;
}

.dlgwrapper button {
  font-size: 1.2em;
  margin-left: 20px;
  background-color: #a6d2ce;
}

.dlgwrapper input {
  width: 100%;
}

.dlgwrapper .title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
}

.dlgwrapper .actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px;
  margin: 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
</style>

<template>
  <bank-navi :user="user"></bank-navi>
  <GDialog v-model="dlgNewSite" max-width="500">
    <div class="dlgwrapper">
      <div class="content">
        <div class="title">New Site</div>
        <p>追加するサイトのURLを入力してください。(https:// は不要)</p>
        <input id="new-site" type="text" class="new-site" v-model="newSiteUrl" />
      </div>

      <div class="actions">
        <button class="btn dlgCancel" @click="dlgNewSite ^= 1">CANCEL</button>
        <button class="btn dlgOk" @click="addNewSite">OK</button>
      </div>
    </div>
  </GDialog>
  <div class="settings">
    <div class="py-0 container-fluid">
      <div class="col-lg-12 col-12">
        <span class="title"> 読取設定</span>
      </div>

      <div class="row">
        <div class="col-lg-12col-md-12 mb-md-0 mb-4">
          <div class="selGroup">
            <label for="selGroup"> Group: {{ group }} </label>
            <select class="selGroup" id="selGroup" v-model="group">
              <option v-for="nn in glist" :value="nn[0]" :key="nn">
                {{ nn[1] }}
              </option>
            </select>
            <button class="btn add-group" @click="addGroup">new Group</button>
          </div>

          <div class="selSite site-frame">
            <label for="selSite"> Site ({{ curIdx }})</label>
            <select class="selSite" id="selSite" v-model="curIdx" @change="onChangeSite">
              <option v-for="(nn, index) in sites" :value="index" :key="index"
                style="background-image:url({{getImg(nn.url)}})">
                <img :src="getImg(nn.url)" />
                {{ nn.url }}
              </option>
            </select>
            <button class="btn dlg" @click="dlgNewSite ^= 1">Add</button>
            <button class="btn save" @click="save">Save</button>
          </div>

          <div v-if="curSite" class="col-lg-12col-md-12 mb-md-0 mb-4" :key="render">
            <div class="url">
              <label for="url">Site URL</label>
              <img class="site-url" :src="getImg(curSite.url)" />
              {{ getImg(curSite.url) }}
              <input id="url" type="text" class="url" v-model="curSite.url" />

              <tag-input-ary @emchange="updateDates" :name="aryNames.dates" :ary="curSite.dates" />

              <tag-input-ary @emchange="updateTargets" :name="aryNames.targets" :ary="curSite.targets" />

              <tag-input-ary @emchange="updateCaptures" :name="aryNames.captures" :ary="curSite.captures" />
              <tag-input-ary @emchange="updateStops" :name="aryNames.stops" :ary="curSite.stops" />
              <p>Skip で tag="" (空白）の場合、タグ内テキストの判定をします</p>
              <tag-input-ary @emchange="updateSkips" :name="aryNames.skips" :ary="curSite.skips" />

              <tag-input-ary @emchange="updateImages" :name="aryNames.images" :ary="curSite.img" />
            </div>
            <table class="table pages"></table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-12">
      <button class="btn toData" @click="branch('/Urls')">URLリストへ</button>
    </div>
  </div>
</template>
<script>
import TagInputAry from "@/components/TagInputAry.vue";
import { SiteSet, getAPIBASE, saveSiteData } from "../Repository";
import { logoImages } from "./Pages";
import BankNavi from "@/components/UrlbankNavi.vue";

export default {
  name: "Pages",
  components: {
    TagInputAry,
    BankNavi,
  },
  data() {
    return {
      newSiteUrl: "",
      dlgNewSite: false,
      render: 0,
      group: "vn",
      apibase: getAPIBASE(),
      curIdx: 0,
      sites: [],
      curSite: null,
      aryNames: {
        dates: "Dates",
        targets: "Targets",
        captures: "Captures",
        stops: "Stops",
        skips: "Skips",
        images: "Images",
      },
      glist: [
        ["vn", "Vietnam"],
        ["ph", "Philippines"],
        ["kh", "Cambodia"],
        ["id", "Indnesia"],
        ["mm", "Myama"],
        ["la", "Laos"],
        ["my", "Malaysia"],
        ["th", "Thailand"],
        ["bn", "Brunei"],
        ["sg", "Singapore"],
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.mail;
    },
    list() {
      return this.$store.state.pages;
    },
    /*
    sites() {
      return this.$store.state.siteData;
    },*/
  },
  updated() { },
  mounted() {
    this.sites = this.$store.state.siteData;
    this.curSite = this.sites[this.curIdx];
  },
  methods: {
    addNewSite() {
      if (this.newSiteUrl == "") {
        alert(" no url");
        return;
      }
      if (this.newSiteUrl.indexOf("http") >= 0) {
        alert(" no need http://");
        return;
      }
      if (this.newSiteUrl.indexOf("/") >= 0) {
        alert(" /(slash) detected. please input only site body");
        return;
      }
      var site = new SiteSet();
      site.url = this.newSiteUrl;
      this.$store.commit("onSiteAdd", site);
      this.dlgNewSite ^= 1;
    },
    getImg(url) {
      if (!url) {
        return "";
      }
      for (var i in logoImages) {
        if (url.indexOf(logoImages[i][0]) >= 0) {
          return "/logo/" + logoImages[i][0] + ".png";
        }
      }
      return "";
    },

    onChangeSite() {
      this.curSite = this.sites[this.curIdx];
      this.render++;
    },
    updateDates(obj) {
      console.log(obj);
      this.curSite.dates = obj.ary;
    },
    updateTargets(obj) {
      console.log(obj);
      this.curSite.targets = obj.ary;
    },
    updateCaptures(obj) {
      console.log(obj);
      this.curSite.captures = obj.ary;
    },
    updateStops(obj) {
      console.log(obj);
      this.curSite.stops = obj.ary;
    },
    updateSkips(obj) {
      console.log(obj);
      this.curSite.skips = obj.ary;
    },
    updateImages(obj) {
      console.log(obj);
      this.curSite.img = obj.ary;
    },
    async save() {
      if (!confirm("セーブします")) return;
      this.sites[this.curIdx] = this.curSite;
      //console.log(this.sites);
      let str = JSON.stringify(this.sites);
      //console.log(str);
      await saveSiteData(this.group, str).then((res) => {
        console.log(res);
        if (res.status == "ok") alert("Save しました");
        else if (res.reason == "same data")
          alert("中止しました. reason:" + res.reason);
      });
    },
    checkSkips() {
      console.log(this.sites);
      for (var i in this.sites) {
        console.og("skips" in this.sites[i]);
        if ("skips" in this.sites[i]) continue;
        this.sites[i].skips = [];
        console.log("add skips[] :" + this.site[i].url);
      }
    },
    branch(url) {
      this.$router.push(url);
    },
  },
};
</script>
