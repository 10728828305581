import {
  getAPIBASE,
  onUpdateArticleEdit,
  onUpdateDesc,
  onUpdatePublished,
  onFileCreate,
  //onAddFilePreview,
  onDeleteImage,
  loadReplaceJP,
  loadDaily,
  onSummarize,
  getDescriptionText,
  onPreviewID
} from "../Repository";
import VueImageBase64 from "vuejs-image-base64";
import { logoImages } from "./Pages";
import { toJPUnit, textStd, textStdInit } from "./styledJP";
//import { GDialog } from 'gitart-vue-dialog'
import BankNavi from "@/components/UrlbankNavi.vue";

export default {
  name: "Page",
  components: {
    // GDialog,
    BankNavi,
    //Datepicker,
    VueImageBase64,
  },
  data() {
    return {
      minRange: [...Array(6).keys()].map((num) => num * 10),
      hRange: [...Array(17).keys()].map((num) => 5 + num),

      rate: 0.0056,
      yen: 1000,
      vnd: 0,
      yenStr: "",
      vndStr: "",
      tweet_date: "",
      tweetOk: "",
      page: 0,
      noCaptured: true,
      render: 0,
      apibase: getAPIBASE(),
      descEn: [],
      descJP: [],
      dialogState: false,
      /*
      groups: [
        "society",
        "economy",
        "tech",
        "life",
        "tourism",
        "world",
        "food",
        "sports",
        "japan",
        "other",
      ], */
      tags: [
        "p", "h1", "h2", "h3", "h4", "span", "img", "MC1", "MC2", "MC3"
      ]
    };
  },
  computed: {
    sumLen() {
      if (!this.$store.state.preview.summarize)
        return 0;
      return this.$store.state.preview.sumshort.length + 24 + this.$store.state.preview.descJP[0].text.length + 24;
    },

    groups() {
      return this.$store.state.groups;
    },
    mode() {
      return this.$store.state.edit.mode;
    },
    obj() {
      return this.$store.state.preview;
    },
    descReady() {
      console.log(this.$store.state.preview.desc);
      if (undefined != this.$store.state.preview.desc)
        return this.$store.state.preview.desc.length > 0;
      return false;
    },
    desc() {
      if (this.$store.state.edit.mode == "org")
        return this.$store.state.preview.desc;
      return this.$store.state.preview.descJP;
    },
    user() {
      return this.$store.state.mail;
    },
    images() {
      var images = []
      //console.log(this.$store.state.preview)
      for (var ii = 0; ii < this.$store.state.preview.imgs.length; ii++) {
        //console.log(this.$store.state.preview.imgs[ii])
        images.push({ name: this.$store.state.preview.imgs[ii], deleted: false, url: this.getImgUrl(this.$store.state.preview.imgs[ii]) });
      }
      return images;
    }
  },
  updated() { },
  mounted() {
    loadReplaceJP();
    loadDaily();
    const collection = document.getElementsByClassName("desc-edit");
    for (let i = 0; i < collection.length; i++) {
      collection[i].click();
    }
    this.tweet_date = this.obj.date;
  },
  methods: {
    swapMonthAndDayIfFutureDate(dateString) {
      const currentDate = new Date();
      const targetDate = new Date(dateString);

      if (targetDate > currentDate) {
        const year = targetDate.getUTCFullYear();
        const month = targetDate.getUTCMonth() + 1; // 月は0から始まるため、+1する
        const day = targetDate.getUTCDate();

        // 月と日を入れ替える
        const swappedDate = `${year}-${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}${dateString.slice(10)}`;
        return swappedDate;
      }
      return dateString; // 元の日付文字列を返す
    },
    dateSwap() {
      var obj = this.$store.state.preview;
      var nd = this.swapMonthAndDayIfFutureDate(obj.date);
      this.$store.commit("onUpdatePreviewDate", nd)
      this.update()
    },
    reloadPage() {
      console.log(this.$store.state.preview._id);
      onPreviewID(this.$store.state.preview._id);
    },
    onSelTime(event, hh, mm) {
      const elements = document.querySelectorAll(".min"); // class="min"のすべての要素を取得
      elements.forEach(element => {
        element.classList.remove("selected"); // 各要素から"class"が"selected"のクラスを削除
      });

      var dt = new Date(this.tweet_date);
      console.log(`${hh}:${mm}`)
      dt.setHours(hh);
      dt.setMinutes(mm);
      this.tweet_date = dt.toString()
      event.target.classList.add("selected")
    },
    getDescText() {
      return getDescriptionText();
    },
    delImage(idx) {
      let id = this.$store.state.preview._id;
      let img = this.$store.state.preview.imgs[idx];
      if (confirm(img + " を削除します")) {
        onDeleteImage(id, img, idx);
      }
    },
    setYenStr() {
      this.yenStr = toJPUnit(this.yen)
      this.vndStr = toJPUnit(this.vnd)
    },
    vndYen() {
      this.rate = this.$store.state.daily.Market[9].val.toFixed(5);
      this.yen = this.vnd * this.rate;
      this.setYenStr()
    },
    yenVnd() {
      console.log(this.yen)
      console.log(this.$store.state.daily.Market)
      this.rate = this.$store.state.daily.Market[9].val.toFixed(5);
      this.vnd = this.yen / this.rate;
      this.setYenStr()
    },

    zoneId(num) {
      return "zone" + num.toString();
    },
    // drag obj
    DGstart: function (ev) {
      console.log("dropStart id=" + ev.target.id);
      ev.dataTransfer.setData("text/plain", ev.target.id);
    },
    // drag obj
    DGdropEnd: function (ev) {
      ev.preventDefault();
      console.log("dropEnd");
    },
    // drop target
    DGover: function (ev) {
      ev.preventDefault();
      var tag = document.getElementById(ev.target.id);
      if (tag) tag.classList.add("drop-over");
    },
    // drop target
    DGleave: function (ev) {
      ev.preventDefault();
      var tag = document.getElementById(ev.target.id);
      if (tag) tag.classList.remove("drop-over");
    },
    // drop target
    DGdrop: function (ev) {
      ev.preventDefault();
      console.log("drop -----------");
      if (ev.target.id.indexOf("zone") < 0) return;
      ev.target.classList.remove("drop-over");
      var lineIdx = parseInt(ev.target.id.split("zone")[1]);
      var pid = parseInt(ev.dataTransfer.getData("text"));
      if (isNaN(pid)) return;
      if (pid == null) return;
      this.$store.commit("onImgPreview", { lineIdx, pid });
    },

    dropImg: function (ev) {
      ev.preventDefault();
      console.log("dropped ${ev}");
    },

    handleChange: function (data) {
      if (data.result) {
        console.log(data.fileName)
        onFileCreate(data, this.$store.state.preview._id, data.fileName);
        //onAddFilePreview(data.fileName);
      } else {
        this.errors = data.messages;
      }
    },
    insertAbove(idx) {
      this.$store.commit("onEditNewline", idx);
    },
    insertBelow(idx) {
      this.$store.commit("onEditNewline", idx + 1);
      //this.$store.commit("onEditNewline", idx, -1)
    },

    onTagSelect(ev, index) {
      console.log(ev.target.value)
      console.log(index)
      this.$store.commit("onEditTag", { idx: index, tag: ev.target.value })
    },
    resizeTx(event) {
      event.target.style.height = "auto";
      event.target.style.height = `${event.target.scrollHeight + 0}px`;
    },
    checkTag(tag1, tag2) {
      return (tag1 == tag2)
    },
    swapTag(index, obj) {
      console.log(index)
      console.log(obj.tag)
      if (obj.tag == "img")
        return;
      if (obj.tag == "span")
        this.$store.commit("onEditTag", { idx: index, tag: "p" })
      else
        this.$store.commit("onEditTag", { idx: index, tag: "span" })

    },

    checkGroup(txt, val) {
      if (txt.indexOf(val) >= 0) {
        return true;
      }
      return false;
    },
    onCheckbox() {
      var ret = [];
      for (var ii in this.groups) {
        if (document.getElementById(this.groups[ii]))
          if (document.getElementById(this.groups[ii]).checked)
            ret.push(this.groups[ii]);
      }
      let tt = ret.join(",");
      console.log(tt)
      this.$store.commit("onEditTypes", tt);
    },

    publish() {
      onUpdatePublished();
      this.$router.push("/Page");
      //this.$store.commit("onEditPublish");
    },
    toggleMode() {
      if ("org" == this.$store.state.edit.mode) this.setMode("ja");
      else this.setMode("org");
    },
    setMode(md) {
      this.$store.commit("onEditMode", md);
    },
    jpSTD() {
      var desc = this.$store.state.preview.descJP;
      textStdInit(this.$store.state.replaces)
      for (var ii in desc) {
        let text = textStd(desc[ii].text);
        this.$store.commit("onUpdateDescJP", {
          idx: ii,
          text: text,
        });
      }
      console.log("clean jp text");
    },

    jpCR() {
      if (!confirm("自動改行は未完成のため不要な改行が入る場合があります。\n 本当に実行してもよろしいですか？"))
        return;
      var desc = this.$store.state.preview.descJP;
      for (var ii in desc) {
        let text = desc[ii].text.replaceAll("。", "。\n\n");
        this.$store.commit("onUpdateDescJP", {
          idx: ii,
          text: text,
        });
      }
      console.log("clean jp text");
    },
    update() {
      onUpdateArticleEdit(this.$store.state.preview.id);
    },
    getDescID(idx) {
      return "ta" + idx;
    },
    getDateID(idx) {
      return "da" + idx;
    },
    getGroupID(idx) {
      return "go" + idx;
    },

    delLine(idx) {
      console.log(" index = " + idx);
      this.$store.commit("onDescDel", idx);
    },
    swapBelowLine(idx) {
      console.log(" index = " + idx);
      this.$store.commit("onSwapBelowLine", idx);
    },


    updateText(idx) {
      console.log(" index = " + idx);
      const elem = document.getElementById(this.getDescID(idx));
      let txt = elem.value;
      onUpdateDesc(idx, txt); //en/jp auto detect by mode
    },
    onApiSum() {
      const elem = document.getElementById("sumsg");
      elem.innerText = "サマライズ中（時間がかかります)"
      const start = Date.now(); // 開始時間
      onSummarize(start, function (st) {
        const elem = document.getElementById("sumsg");
        const end = Date.now(); // 開始時間
        let dt = end - st;
        elem.innerText = (dt.toString() / 1000.0) + "ミリ秒";
      });
    },
    updateSum() {
      const elem = document.getElementById("sum");
      let txt = elem.value;
      //this.$store.commit("onUpdateSum", txt);

      const elem2 = document.getElementById("sumshort");
      let txt2 = elem2.value;
      this.$store.commit("onUpdateSum", { "sum": txt, "sumshort": txt2 });

    },

    dateJP(isodate) {
      var od = isodate.split("T")[0];
      var dt = od.split("-");
      return `${dt[0]}/${dt[1]}/${dt[2]}`;
    },
    getImgUrl(fn) {
      let obj = this.$store.state.preview;
      let id = "";
      if (obj.id) id = obj.id;
      else id = obj._id;
      return this.apibase + `/img/thumb/${id}_${fn}`;
    },
    getFNameByText(txt) {
      for (var ii = 0; ii < this.$store.state.preview.imgs.length; ii++) {
        var fn = this.$store.state.preview.imgs[ii]
        if (txt.indexOf(fn) >= 0)
          return fn;
      }
    },
    getImgUrlByText(text) {
      var fn = this.getFNameByText(text)
      let obj = this.$store.state.preview;
      let id = "";
      if (obj.id) id = obj.id;
      else id = obj._id;
      return this.apibase + `/img/thumb/${id}_${fn}`;
    },


    getPageImg(url) {
      var obj = this.$store.state.preview;
      for (let i in obj.imgUrls) {
        if (obj.imgUrls[i] == url) return this.getImgUrl(obj.imgs[i]);
      }
      return "";
    },
    getClass(tag) {
      return tag + " desc";
    },
    getImg(url) {
      if (!url) {
        return "";
      }
      for (var i in logoImages) {
        if (url.indexOf(logoImages[i][0]) >= 0)
          return "/logo/" + logoImages[i][0] + ".png";
      }
      return "";
    },
    getPage() {
      return this.page;
    },
    back() {
      this.$router.push("/Page");
    },
  },
};
