<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-4 col-md-4 d-flex flex-column">
              <div
                class="mt-8 card card-plain"
                style="background-color: #f8f8ff"
              >
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-gradient">
                    Welcome back
                  </h3>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start" autocomplete="on">
                    <label>Email</label>
                    <br />
                    <input
                      id="email"
                      type="email"
                      placeholder="Email"
                      v-model="mail"
                      name="email"
                      autocomplete="on"
                    />
                    <br />
                    <label>Password</label>
                    <br />
                    <input
                      id="password"
                      type="password"
                      placeholder="Password"
                      v-model="passwd"
                      name="password"
                      autocomplete="on"
                    />
                  </form>
                  <vsud-switch id="rememberMe" name="rememberMe" checked>
                    Remember me
                  </vsud-switch>
                  <div class="text-center">
                    <vsud-button
                      @click="login"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="success"
                      full-width
                      >Sign in
                    </vsud-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover login oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' + require('@/assets/img/studio.png') + ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
//import AppFooter from "@/examples/PageLayout/Footer.vue";
import VsudSwitch from "@/components/VsudSwitch.vue";
import VsudButton from "@/components/VsudButton.vue";
import { loginAPI } from "../Repository";
import appRouter from "../router";

import { mapMutations } from "vuex";

export default {
  name: "SignIn",
  components: {
    //   AppFooter,
    VsudSwitch,
    VsudButton,
  },
  data() {
    return {
      mail: "",
      passwd: "aaa",
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    //let body = document.getElementsByTagName("body")[0];
    //authInit();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    //let body = document.getElementsByTagName("body")[0];
  },
  methods: {
    login: function () {
      loginAPI(this.mail, this.passwd, () => {
        console.log("login ok");
        appRouter.replace("/urls");
      });
      //onAuthByEmail(this.mail, this.passwd);
    },
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },
  mounted() {
    //console.log(firebaseApp);
    // console.log(auth);
    //    console.log(authL);
  },
};
</script>
