<template>
  <bank-navi :user="user"></bank-navi>
  <div class="py-0 container-fluid">
    <div class="col-lg-6 col-7">
      <h3>Magazines / 記事パック一覧</h3>
    </div>

    <div class="row">
      <div class="row my-4">
        <div class="col-lg-12col-md-12 mb-md-0 mb-4">
          <button v-if="isPrevPage" btn @click="prevPage()">prev</button>
          <button v-if="isNextPage" btn @click="nextPage()">next</button>

          <news-card-v2 :page="getPage()" :limit="getLimit()" :max="getMax()" v-bind:counter="render" />
          <button v-if="isPrevPage" btn @click="prevPage()">prev</button>
          <button v-if="isNextPage" btn @click="nextPage()">next</button>
        </div>
      </div>
    </div>
    <a class="btn bg-gradient-success mt-1" href="#" type="button" @click="createMag">Create News</a>
  </div>
</template>

<script src="./Magazines.js"></script>
<style src="./Magazines.css"></style>