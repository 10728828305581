var defReplaces = [
  [/[^0-9] [0-9]+/g, noSpc],
  [/[0-9]+ [^0-9]/g, noSpc],

  [/ %/g, noSpc],
  [/% /g, noSpc],

  [/ \(/g, noSpc],
  [/\( /g, noSpc],
  [/\) /g, noSpc],
  [/ \)/g, noSpc],
  [/。。/g, "。"],
  [/州/g, "省"],
];

var ReplaceAry = [];  //defReplaces;

function includeJa(text) {
  try {
    let gmi = "gmi";
    let regeIncludeHiragana = "^(?=.*[\u3041-\u3096]).*$";
    let regeIncludeKatakana = "^(?=.*[\u30A1-\u30FA]).*$";
    let regeIncludeKanji = "^(?=.*[\u4E00-\u9FFF]).*$";
    let regeHiragana = new RegExp(regeIncludeHiragana, gmi);
    let regeKatakana = new RegExp(regeIncludeKatakana, gmi);
    let regeKanji = new RegExp(regeIncludeKanji, gmi);

    let includeJa = false;
    if (regeHiragana.test(text)) includeJa = true;
    if (regeKatakana.test(text)) includeJa = true;
    if (regeKanji.test(text)) includeJa = true;
    return includeJa;
  } catch (error) {
    //alert(error);
    return false;
  }
}
function noSpc(val) {
  let t = val.split(" ");
  return t.join("");
}
/*
function addReplaceNode(node) {
  for (var ii in ReplaceAry) {
    if (ReplaceAry[ii][0] === node[0])
      return
  }
  ReplaceAry.push(node)
}
*/

export function textStdInit(ary) {
  // copy
  //ReplaceAry = JSON.parse(JSON.stringify(defReplaces))
  ReplaceAry = []
  for (var ii in ary) {
    //console.log(ary[ii]);
    console.log((ary[ii][0].slice(1, -1)));
    //let node = [new RegExp(ary[ii][0].slice(1, -1)), ary[ii][1]]
    let node = [new RegExp(ary[ii][0], "gu"), ary[ii][1]]
    console.log(node)
    ReplaceAry.push(node)
  }
  ReplaceAry = ReplaceAry.concat(defReplaces)
  console.log(defReplaces)
  console.log(ReplaceAry)
}

// --------------------------------
/*export function textStd(c) {
  if (!includeJa(c)) return c;

  for (var tt in ReplaceAry) {
    var t = ReplaceAry[tt];
    console.log(t)
    c = c.replace(t[0], t[1]);
  }
  //console.log("checked:  " + c);
  return c;
}
*/

var desuList = [
  [/(\p{Script=Han}{1})(する。)/ug, "$1します。"],
  [/(\p{Script=Han}{1})(した。)/ug, "$1しました。"],
  [/(\p{Script=Han}{1})(だ。)/ug, "$1です。"],
  [/(\p{Script=Han}{1})(だった。)/ug, "$1でした。"],
  [/(\p{Script=Han}{1})(させる。)/ug, "$1させます。"],
  [/(\p{Script=Han}{1})(となる。)/ug, "$1となります。"],
];

function kanjiDesu(input) {
  for (var ii in desuList) {
    var data = desuList[ii];
    input = input.replace(data[0], data[1]);
  }
  return input;

  /*
  var regex = /(\p{Script=Han}{1})(した。)/ug;
  input = input.replace(regex, "$1しました。");
  regex = /(\p{Script=Han}{1})(だ。)/ug;
  input = input.replace(regex, "$1です。");
  regex = /(\p{Script=Han}{1})(だった。)/ug;
  var output = input.replace(regex, "$1でした。");
  return output;
  */
}

export function textStd(c) {
  if (!includeJa(c)) return c;
  c = kanjiDesu(c)
  for (var tt in ReplaceAry) {
    var t = ReplaceAry[tt];
    c = c.replace(t[0], t[1]);
    /*
    if (typeof (t[0]) == typeof ("abc")) {
      console.log(t)
      c = c.replace(new RegExp(t[0], "g"), t[1]);
    }
    else {
      console.log(t[0])

      c = c.replace(t[0], t[1]);
    }*/
  }
  return c;
}


export function toJPUnit(num) {

  var str = new String(Math.round(num));
  var n = "";
  var count = 0;
  var ptr = 0;
  var kName = ["万", "億", "兆", "京", "垓", "杼", "穰", "溝", "澗", "正", "載", "極", "恒河沙", "阿僧祇", "那由他", "不可思議", "無量大数"];
  for (var i = str.length - 1; i >= 0; i--) {
    n = str.charAt(i) + n;
    count++;
    if (((count % 4) == 0) && (i != 0)) n = kName[ptr++] + n;
  }
  return n;
}



