<template>
  <bank-navi :user="user"></bank-navi>
  <div class="py-0 container-fluid">
    <table class="c-tool">
      <tr>
        <td>
          <a class="btn bg-gradient-success mt-1 mx-1" href="#" type="button" @click="back">もどる</a>
          <a class="btn bg-gradient-warning mt-1 mx-1" href="#" type="button" @click="reloadPage">reload</a>
          <span class="tweeted-text">{{ tweetOk }}</span>
        </td>
        <td>rate {{ rate }}</td>
        <td>
          円： <input class="ed-yen" v-model="yen" @change="yenVnd" />
        </td>
        <td>
          <span id="str-yen">{{ yenStr }}</span>
        </td>
        <td>
          VND: <input class="ed-vnd" v-model="vnd" @change="vndYen" />
        </td>
        <td>
          <span id="str-vnd">{{ vndStr }}</span>
        </td>
      </tr>
    </table>

    <div class="imageBox">
      <VueImageBase64 :maxFileSize="19097152" :thumbnailSize="1280" :drop="true" capture="environment" :multiple="true"
        @handleChange="handleChange" />
      <ul>
        <li v-for="(item, index) in images" :key="index">
          <p class="img-name">
            {{ item.name }}
            <button btn @click="delImage(index)" style="background-color: #f77">
              X
            </button>
          </p>
          <img :id="index" :src="item.url" height="80" draggable="true" v-on:dragstart="DGstart"
            v-on:dragend="DGdropEnd" />
          <div class="img-body" v-if="!item.deleted">
          </div>
        </li>
      </ul>
    </div>

    <div class="row my-0 edheader">
      <div class="page-header3 col-lg-12 col-md-12">
        <table class="header">
          <tr>
            <td>
              <img :src="getImg(obj.url)" width="80" />
            </td>
            <td>
              {{ dateJP(obj.date) }}
            </td>
            <td>
              <a :href="obj.url" target="_blank">
                {{ obj.url }}
              </a>
            </td>
          </tr>
        </table>
      </div>
      <a href="#" class="btn btn-mode" @click="toggleMode">
        {{ mode }}
      </a>
      <a class="btn cmd" href="#" type="button" @click="jpCR">。自動改行</a>
      <a class="btn cmd" href="#" type="button" @click="jpSTD">日本語自動修正</a>

      <a class="btn cmd" href="#" type="button" @click="update">Update</a>
      <a v-if="!obj.published" class="btn publish" href="#" type="button" @click="publish">Draft</a>
      <a v-if="obj.published" class="btn publish published" href="#" type="button" @click="publish">Published!</a>
      <!--

      <a v-if="!obj.tweeted" class="btn tweet" href="#" type="button">Tweet</a>
      <a v-if="obj.tweeted" class="btn tweeted " href="#" type="button">Tweeted<br>({{ obj.tweet_cnt }})</a>
      -->

      <div class="col-lg-12col-md-12 mb-md-0 mb-4">
        <span id="err-msg" class="error"></span>
        <table class="table edit">
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <input class="ed-input ed-date" v-model="obj.date" />
              <button @click="dateSwap" class="text-white">月↔日</button>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <template v-for="(value, index) in groups" :key="index">
                <template v-if="value != '(blank)'">
                  <input class="checkbox" type="checkbox" :id="value" v-if="checkGroup(obj.types, value)" checked
                    @change="onCheckbox" />
                  <input class="checkbox" type="checkbox" :id="value" @change="onCheckbox"
                    v-if="!checkGroup(obj.types, value)" />
                  <label class="desctype" :for=value>{{ value }} </label>
                  <br>
                </template>
              </template>
              <input class="ed-input" style="width: 60%; margin-left: 40px" v-model="obj.types" />
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <button @click="onApiSum">
                要約
              </button>
            </td>
            <td id="sumlen">{{ sumlen }}</td>
            <td>
              <textarea id="sum" class="ed-input" :value="obj.summarize" @change="updateSum()"></textarea>
              <span id="sumsg"></span>
              <textarea id="sumshort" class="ed-input" :value="obj.sumshort" @change="updateSum()"></textarea>

            </td>
          </tr>
          <tr v-for="(oo, index) in   desc" :key="index">
            <td width="30px">
              <select @change="onTagSelect($event, index)">
                <template v-for="(tag, index) in   tags" :key="index">
                  <option v-if="checkTag(tag, oo.tag)" selected :value="tag">{{ tag }}</option>
                  <option v-if="!checkTag(tag, oo.tag)" :value="tag">{{ tag }}</option>
                </template>
              </select>
              <button @click="swapTag(index, oo)" class="text-white bg-cyan-400">P↔Span</button>
              {{ oo.tag }}
            </td>
            <td>
              {{ oo.classes }}
            </td>
            <td class="btncel">
              <button class="btn-alw btncmd" @click="insertAbove(index)">⇑</button><br />
              <button class="btn-alw btncmd" @click="insertBelow(index)">⇓</button><br />
            </td>

            <td width="90%">
              <div v-if="oo.tag == 'img'">
                <div class="dzone">
                  <img :src="getImgUrlByText(oo.text)" height="100" :id="zoneId(index)" class="dropimage"
                    @drop.prevent="DGdrop" @dragover="DGover" @dragenter="checkDrop" @dragleave="DGleave" />
                </div>
              </div>
              <tempalate v-if="oo.tag == 'iframe'">
                <iframe :src="oo.text"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="" frameborder="0" scrolling="no" title="YouTube video player" class="youtube"></iframe>
              </tempalate>
              <template v-else-if="oo.tag != 'img'">
                <textarea class="desc-edit" :id="getDescID(index)" :value="oo.text" @change="updateText(index)"
                  @click="resizeTx" @keyup="resizeTx"></textarea>
              </template>
            </td>
            <td class="btncel">
              <button class="btn-del btncmd" @click="delLine(index)">X</button><br />
            </td>
            <td class="btncel">
              <button class="btn-shift btncmd" @click="swapBelowLine(index)" title="swap below">⇓</button><br />
            </td>
          </tr>
        </table>
      </div>
    </div>
    <a class="btn bg-gradient-success mt-1" href="#" type="button" @click="back">もどる</a>
  </div>
</template>
<script src="./PageEdit.js"></script>
<style src="./PageEdit.css"></style>