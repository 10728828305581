import { createStore } from "vuex";

export default createStore({
  state: {
    // video Magazines
    vm: {
      magazines: [],
      magPage: 0,
      magLimit: 25,
      articles: [],
      curAr: null, //article
      curMag: {},
      curMagIdx: 0,
      curArIdx: 0,
      //curEdit: null,
      editMode: "", //create or update
    },

    //SoftUI
    showNavbar: false,
    showSidenav: false,
    // app auth
    siteData: {},
    daily: {},
    user: null, //user object
    mail: "",
    token: "",
    isAuth: false,
    email: "",
    viewMode: "", // admin or not
    urls: [],
    dailyList: [],
    counter: 0,
    loginUser: {},
    pages: [],
    tweets: [],
    tw_reservations: [],
    preview: {},
    factoryStat: {},
    factoryBuild: false,
    factoryDeploy: false,
    factoryPubAll: false,
    edit: { mode: "org" },
    // Pages state
    arPage: 0,
    pageFilter: {
      acAll: true,
      published: false,
      date: "",
      isMonth: "true",
      group: "",
    },
    groups: [
      "(blank)",
      "society",
      "economy",
      "tech",
      "life",
      "world",
      "tourism",
      "japan",
      // "other",
      // "sports",
      // "food",
    ],
    replaces: [
      ["/。\\./g", "。"],
      ["/。。/g", "。"],
      ["/困難な/g", "難しい"],
      ["/いるという。/g", "いるそうです。"],
      ["/いる。/g", "います。"],
      ["/いた。/g", "いました。"],
      ["/えた。/g", "えました。"],
      ["/だった。/g", "でした。"],
      ["/なった。/g", "なりました。"],
      ["/語った。/g", "語りました。"],
      ["/述べた。/g", "述べました。"],
      ["/れる。/g", "れます。"],
      ["/と言った。/g", "とコメント。"],
      ["/加えた。/g", "加えました。"],
      ["/州/g", "省"],
    ],

  },
  getters: {
    getUser: (state) => () => {
      return state.mail;
    },
  },
  actions: {
    checkLogin({ commit }) {
      console.log("checkLogin");
      commit("checkSessionToken");
    },
    auth({ commit }, user) {
      console.log(user);
      commit("onAuthChanged", user.mail, user.token);
    },
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
  },
  mutations: {
    //env
    onArPageReset(state) {
      state.arPage = 0;
      //this.onGetPages(state.arPage);
    },
    onArPageNext(state) {
      state.arPage++;
      //this.onGetPages(state.arPage);
    },
    onArPagePrev(state) {
      state.arPage--;
      if (state.arPage < 0) state.arPage = 0;
      //this.onGetPages(state.arPage);
    },

    onPageListSumFlag(state, idx) {
      return state.pages[idx].sumFlag = true;
    },
    onUpdatePreviewDate(state, data) {
      state.preview.date = data;
    },

    onUpdateSum(state, data) {
      state.preview.summarize = data.sum;
      state.preview.sumshort = data.sumshort;
    },
    onUpdateDesc(state, data) {
      if (state.edit.mode == "org")
        state.preview.desc[data.idx].text = data.text;
      else state.preview.descJP[data.idx].text = data.text;
    },
    onUpdateDescJP(state, data) {
      state.preview.descJP[data.idx].text = data.text;
    },
    onFactoryStat(state, dic) {
      state.factoryStat = dic;
    },
    onFactoryBuild(state, sw) {
      state.factoryBuild = sw;
    },
    onFactoryDeploy(state, sw) {
      state.factoryDeploy = sw;
    },
    onFactoryPubAll(state, sw) {
      state.factoryPubAll = sw;
    },

    onTweets(state, data) {
      state.tweets = data;
    },

    onTweetReservations(state, data) {
      state.tw_reservations = data;
    },




    onReplaceAry(state, data) {
      state.replaces = data;
      console.log(state.replaces)
    },

    onNewReplace(state, data) {
      state.replaces.push(data);
    },
    onDeleteReplace(state, idx) {
      state.replaces.splice(idx, 1);
      /*
      var ary = []
      for (var ii = 0; ii < state.replaces.length; ii++) {
        if (ii != idx) {
          ary.push(state.replaces[ii])
          console.log(state.replaces[ii])
        }
      }
      state.replaces = ary;
      */
      console.log(state.replaces)
    },

    onUpdateReplace(state, data) {
      state.replaces[parseInt(data.idx)] = [data.node[0], data.node[1]];
    },
    onVmMagazines(state, data) {
      state.vm.magazines = data;
    },
    onVmMagazinesNew(state, data) {
      state.vm.magazines.push(data);
    },


    onVmMagazineSelect(state, id) {
      console.log(state.vm.magazines);
      state.vm.curMag = state.vm.magazines[id];
      console.log(state.vm)
      state.vm.curMagIdx = id;
    },

    // article --------------------------------------------------
    onVmArticleLoad(state, ar) {
      state.vm.articles.push(ar);
      state.vm.curAr = state.vm.articles[state.curArIdx];
      state.vm.curArIdx = state.vm.articles.length - 1;
      return state.curAr;
    },

    /*
    onVmArticleLineUpdate(state, lines) {
      // state.vm.curAr.lines = data;
      console.log(state.vm.curAr)
      state.vm.curAr.update(lines)
    }, */
    onVmArticleDateUpdate(state, date) {
      state.vm.curAr.date = date;
    },

    onVmArticleDeletedAt(state, idx) {
      state.vm.articles[idx].deteledAt = new Date().toISOString();
    },

    onVmArticleDel(state, idx) {
      state.vm.articles.splice(idx, 1);
    },

    // article
    onVmArticleReset(state) {
      state.vm.articles = [];
    },

    // article
    onVmArticleEnableType(state, { idx, type }) {
      if (state.vm.articles[idx].type.indexOf(type) >= 0) {
        return;
      } else {
        if (state.vm.articles[idx].type == "") state.vm.articles[idx].type = type;
        else state.vm.articles[idx].type = state.vm.articles[idx].type + "," + type;
      }
    },
    // article
    onVmArticleDisableType(state, { idx, type }) {
      var tts = state.vm.articles[idx].type.split(",");
      var str = "";
      for (var xx in tts) {
        if (tts[xx] == type) continue;
        if (str == "") str = tts[xx];
        else str = str + "," + tts[xx];
      }
      state.vm.articles[idx].type = str;
    },

    // article
    onVmArticleTypeToggle(state, { idx, type }) {
      var flg = state.vm.articles[idx].type.indexOf(type) >= 0;
      if (!flg) {
        this.commit("onVmArticleEnableType", { idx, type });
      } else {
        this.commit("onVmArticleDisableType", { idx, type });
      }
    },

    // Image --------------
    onVmArticleDelImage(state, idx) {
      return state.vm.curAr.delImage(idx);
    },
    // Image --------------
    onVmArticleAddImage(state, { url, name }) {
      return state.vm.curAr.addImage(url, name);
    },

    onVmArticleSelect(state, id) {
      console.log(state.vm.articles);
      state.vm.curAr = state.vm.articles[id];
      state.vm.curArIdx = id;
      console.log(state.vm)
    },
    onVmArticleTextUpdate(state, text) {
      state.vm.curAr.text = text;

    },
    onVmArticleMemoUpdate(state, text) {
      state.vm.curAr.memo = text;

    },


    onVmArticleAdd(state, data) {
      console.log(state.vm.articles) //.push(data);
      console.log(typeof (state.vm.articles)) //.push(data);
      state.vm.articles.push(data);
    },

    onVmArticles(state, data) {
      //console.log(typeof (data))
      //console.log(data)
      state.vm.articles = data;
    },

    onVmArticlePicUpdate(state, { lineIdx, pic }) {
      if (undefined === pic) {
        return;
      }
      state.vm.curAr.lines[lineIdx].pic = {
        name: state.vm.curAr.images[pic].name,
        url: state.vm.curAr.images[pic].url,
      };
    },

    onMagazineDateUpdate(state, date) {
      state.vm.curMag.date = date;
    },
    onVmCurMagDate(state, date) {
      state.vm.curMag.date = date;
    },
    onMagazineMemoUpdate(state, memo) {
      state.vm.curMag.memo = memo;
    },
    onVmCurMagMemo(state, memo) {
      state.vm.curMag.memo = memo;
    },


    onEditNewline(state, idx) {
      let node = { "tag": "p", "classes": [], "text": "" }

      if (state.edit.mode == "org") {
        if (idx == state.preview.desc.length)
          state.preview.desc.push(node)
        else
          state.preview.desc.splice(idx, 0, node)
      } else {
        console.log(idx)
        console.log(state.preview.descJP.length)

        if (idx == state.preview.descJP.length)
          state.preview.descJP.push(node)
        else
          state.preview.descJP.splice(idx, 0, node)
        console.log(idx)
      }


    },

    onEditPublish(state) {
      state.preview.published = !state.preview.published;
    },
    onEditTypes(state, val) {
      state.preview.types = val;
    },

    onEditTag(state, data) {
      if (state.edit.mode == "org")
        state.preview.desc[data.idx].tag = data.tag;
      else state.preview.descJP[data.idx].tag = data.tag;
    },

    onDescDel(state, idx) {
      console.log("del idx =" + idx);
      if (state.edit.mode == "org") {
        state.preview.desc.splice(idx, 1);
      } else {
        if (state.preview.descJP && state.preview.descJP.length > 0)
          state.preview.descJP.splice(idx, 1);
      }
    },

    onSwapBelowLine(state, idx) {
      console.log("del idx =" + idx);
      if (state.edit.mode == "org") {
        if (state.preview.desc.length <= idx)
          return
        let tmp = state.preview.desc[idx]
        state.preview.desc[idx] = state.preview.desc[idx + 1]
        state.preview.desc[idx + 1] = tmp;
      } else {
        if (state.preview.descJP.length <= idx)
          return
        if (state.preview.descJP[idx].tag == 'p')
          state.preview.descJP[idx].tag = 'span'
        if (state.preview.descJP[idx + 1].tag == 'p')
          state.preview.descJP[idx + 1].tag = 'span'

        let tmp = state.preview.descJP[idx]
        state.preview.descJP[idx] = state.preview.descJP[idx + 1]
        state.preview.descJP[idx + 1] = tmp;
      }
    },



    onImgPreview(state, data) {
      if (state.edit.mode == "org") {
        return
      } else {
        if (state.preview.descJP && state.preview.descJP.length > data.lineIdx)
          if (state.preview.descJP[data.lineIdx].tag == "img")
            state.preview.descJP[data.lineIdx].text = state.preview.imgs[data.pid]
      }

    },


    /*
    onUpdateDescEn(state, data) {
      console.log(data);
      console.log(data.idx);
      console.log(data.text);
      state.preview.desc[data.idx].text = data.text;
    },
    
    onUpdateDescJP(state, data) {
      console.log(data);
      console.log(data.idx);
      console.log(data.text);
      state.preview.descJP[data.idx].text = data.text;
    },
    */
    onReject(state, id) {
      for (var ii in state.urls) {
        if (id == state.urls[ii].id) {
          console.log(id)
          state.urls.splice(ii, 1)
          return
        }
      }
    },
    onDaily(state, data) {
      state.dailyList = data;
    },

    onUrls(state, data) {
      state.urls = data;
      state.counter = data.length;
    },
    onPages(state, data) {
      state.pages = data;
    },
    onSetPreviewObj(state, obj) {
      if (!obj._id)
        obj._id = obj.id;
      state.preview = obj;
      //state.previewId = obj.id;
      state.edit.mode = "org";
      if (state.preview.desc && state.preview.desc.length > 0) {
        if (state.preview.desc[0].tag == "img")
          state.preview.desc[0].text = "(skip)";
      }
      if (state.preview.descJP && state.preview.descJP.length > 0) {
        if (state.preview.descJP[0].tag == "img")
          state.preview.descJP[0].text = "(skip)";

        state.edit.mode = "jp";
      }
    },
    onDeleteImg(state, idx) {
      state.preview.imgs.splice(idx, 1);

    },
    onAddFilePreview(state, name) {
      if (!state.preview.imgUrls.includes(name)) {
        state.preview.imgUrls.push(name);
      }
      if (!state.preview.imgs.includes(name)) {
        state.preview.imgs.push(name);
      }
      console.log(state.preview);
    },
    onSetPreview(state, idx) {
      state.edit.mode = "org";
      state.preview = state.pages[idx];
      //state.previewId = state.pages[idx].id;
      if (state.preview.desc[0].tag == "img")
        state.preview.desc[0].text = "(skip)";
      if (state.preview.descJP && state.preview.descJP.length > 0) {
        state.edit.mode = "jp";
        if (state.preview.descJP[0].tag == "img")
          state.preview.descJP[0].text = "(skip)";
      }
    },

    onNewArticle(state, obj) {
      if (!obj.summarize)
        obj.summarize = "";
      state.preview = obj;
      state.edit.mode = "jp";
    },


    onEditMode(state, md) {
      state.edit.mode = md;
    },

    onGroupFilter(state, gr) {
      state.pageFilter.group = gr;
    },
    onPageFilter(state, data) {
      state.pageFilter = data;
    },
    onSiteData(state, data) {
      state.siteData = data;
    },
    onSiteAdd(state, obj) {
      state.siteData.push(obj);
    },
    onDailyJson(state, obj) {
      state.daily = obj;
    },

    // user
    onUserLoad(state, obj) {
      state.users.push(obj);
    },
    onUserReset(state) {
      state.users = [];
    },
    onLoginUser(state, obj) {
      state.loginUser = obj;
    },
    onUserObj(state, user) {
      //yyyy-mm
      console.log("onUserObj=>" + user);
      state.user = user;
    },
    checkSessionToken(state) {
      console.log(state.token);
      console.log("checkSessionToken");
    },
    onAuthChanged(state, user) {
      state.mail = user.mail;
      state.token = user.token;
      state.isAuth = true;
      //onAuthByEmail( mail, )
    },
    onLogout(state) {
      state.mail = "";
      state.token = "";
      state.isAuth = false;
      console.log("onAuthLogout");
    },

    // UI
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
  },
});
