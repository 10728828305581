<style></style>

<template>
  <bank-navi :user="user"></bank-navi>
  <div class="py-0 container-fluid">
    <div class="col-lg-12 col-12">
      <vue-ar-search :apibase="apibase" />
    </div>

    <div class="row">
      <span class="title">Preload
      </span>
    </div>
  </div>
</template>
<script>
import BankNavi from "@/components/UrlbankNavi.vue";

import {
  onPreviewID,
} from "../Repository";

export default {
  name: "Pages",
  components: {
    BankNavi,
  },

  data() {
    return {
    };
  },
  computed: {
  },
  updated() { },
  mounted() {
    onPreviewID(this.$route.params.id);
  },
  methods: {},
};
</script>
