/* 
vpressAdmin から移植
firebase -> サーバーの専用APIに変更
*/
//import firebase from "firebase/compat/app";
//import { VmMagazine, VmArticle } from "./videoMag.js";
import { VmArticle } from "./videoMag.js";
//import router from "./router";
//import appRouter from "./router";
//import { onBeforeUnmount } from "vue";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";



import "firebase/compat/auth";
import store from "./store";
import { getHeader, getAPIBASE, ApiResCheck } from "./Repository"


// Articles  (V2) ----------------------------------------------------------------------------------

Date.prototype.setISO8601 = function (string) {
  var regexp =
    "([0-9]{4})(-([0-9]{2})(-([0-9]{2})" +
    "(T([0-9]{2}):([0-9]{2})(:([0-9]{2})(.([0-9]+))?)?" +
    "(Z|(([-+])([0-9]{2}):([0-9]{2})))?)?)?)?";
  var d = string.match(new RegExp(regexp));

  var offset = 0;
  var date = new Date(d[1], 0, 1);

  if (d[3]) {
    date.setMonth(d[3] - 1);
  }
  if (d[5]) {
    date.setDate(d[5]);
  }
  if (d[7]) {
    date.setHours(d[7]);
  }
  if (d[8]) {
    date.setMinutes(d[8]);
  }
  if (d[10]) {
    date.setSeconds(d[10]);
  }
  if (d[12]) {
    date.setMilliseconds(Number("0." + d[12]) * 1000);
  }
  if (d[14]) {
    offset = Number(d[16]) * 60 + Number(d[17]);
    offset *= d[15] == "-" ? 1 : -1;
  }

  offset -= date.getTimezoneOffset();
  var time = Number(date) + offset * 60 * 1000;
  this.setTime(Number(time));
};
/*
function sortDate(a, b) {
  //  var date1 = new Date().setISO8601(a.date);
  //  var date2 = new Date().setISO8601(b.date);
  //console.log(date1);
  return a.date > b.date ? 1 : -1;
}*/


// Magazines (V2) ----------------------------------------------------------------------------------

export function onMagazineSum(data) {
  var cntImg = 0;
  var cntTxt = 0;
  for (var aa in store.state.vm.articles) {
    console.log(store.state.vm.articles[aa])

    //cntImg += store.state.vm.articles[aa].images.length;
    //cntTxt += store.state.vm.articles[aa].text.length;
  }
  //  store.state.vm.curNews.numPic = cntImg;
  //  store.state.curNews.numChar = cntTxt;

  data.numPic = cntImg;
  data.numChar = cntTxt;

  console.log("sum");
  console.log(cntImg);
}

// ---------------------------------------------------------------------------------
// Magazine List ---------------------------
export async function onVmMagazineNextList() {
  if (store.state.vm.magazines.length > 0) {
    store.state.vm.magPage += 1;
    onGetMags()
  }
}

export async function onVmMagazinePrevList() {
  if (store.state.vm.magPage > 0) {
    store.state.vm.magPage -= 1;
    onGetMags()
  }
}


function getDict(instance) {
  const obj = {};

  for (const key of Object.keys(instance)) {
    if (!(instance[key] instanceof Function)) {
      obj[key] = instance[key];
    }
  }

  return obj;
}
// Magazine Articles ------------------------------------------------------------------------
export async function getVmArticles(magId) {
  var url =
    getAPIBASE() +
    `/api/v1/video/articles/mag/${magId}`;
  //  if (noCaptured) url = url + "&captured=0";
  await fetch(url, {
    method: "GET",
    headers: getHeader(),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      var ary = [];
      for (let idx in data) {
        var ac = VmArticle.create(data[idx])
        ary.push(ac)
      }
      store.commit("onVmArticles", ary);
      console.log(ary)
      if (ary.length > 0)
        console.log(typeof (ary[0]))
      return data;
    });
}

export async function onVmArticleDelete(obj) {
  console.log(obj)
  var delObjs = [obj._id];

  //clone.id = clone._id;
  var url = getAPIBASE() + `/api/v1/video/articles/`;
  await fetch(url, {
    method: "DELETE",
    headers: getHeader(),
    body: JSON.stringify({ ids: delObjs }),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      getVmArticles(obj.mag)
      return data;
    });
}


// create ----------------
export async function onVmArticleCreate(magId) {
  // store は try catch の外！
  var obj = new VmArticle();
  obj.mag = magId;
  obj.author = store.state.mail;
  obj.idx = store.state.vm.articles.length;
  obj.text = "empty text"
  obj.updateLinesByText(obj.text)
  var vals = getDict(obj)
  store.commit("onVmArticleAdd", obj)
  console.log(vals)
  await newVmArticle(vals)
  // get latest list
  await getVmArticles(magId);
  console.log(obj);
  return { res: true, msg: "add ok", ret: vals };
}

export async function newVmArticle(obj) {
  console.log(obj)
  let clone = JSON.parse(JSON.stringify(obj));
  delete clone._id;
  delete clone.id;
  var url = getAPIBASE() + `/api/v1/video/articles/`;
  await fetch(url, {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(clone),
  })
    .then((res) => {
      ApiResCheck(res);
      console.log(res)
      return res.json();
    })
    .then((data) => {
      console.log(data);
      return data;
    });
}

export async function onVmArticleUpdate(data) {
  console.log("update article")
  console.log(data);
  var id = data._id
  let clone = JSON.parse(JSON.stringify(data));
  console.log(clone);
  var url =
    getAPIBASE() +
    `/api/v1/video/articles/${id}/`;
  //  if (noCaptured) url = url + "&captured=0";
  await fetch(url, {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(clone),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      return data;
    });
}



// Magazines ------------------------------------------------------------------------

// create ----------------
export async function onMagazineCreate() {
  // store は try catch の外！
  //var obj = new VmMagazine();
  //var vals = getDict(obj)
  var vals = { author: store.state.mail, date: new Date().toISOString() }
  var obj = newMagazine(vals);
  console.log(obj)
  onGetMags();
  store.commit("onMagazineAdd", obj);
  console.log(obj);
  return { res: true, msg: "add ok", obj: obj };
}


export async function onMagazineUpdate(data) {
  console.log("update magazine");
  //onMagazineSum(data);
  console.log(data);
  var url =
    getAPIBASE() +
    `/api/v1/video/magazines/${data._id}/`;
  //  if (noCaptured) url = url + "&captured=0";
  await fetch(url, {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      return data;
    });
}


async function newMagazine(obj) {
  var url = getAPIBASE() + `/api/v1/video/magazines/`;
  await fetch(url, {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(obj),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      store.commit("onVmMagazinesNew", data.data)
      //router.push("/VmPage");
      return data;
    });

}



export async function onMagazineDelete(obj) {
  console.log(obj)
  var delObjs = [obj._id];

  //clone.id = clone._id;
  var url = getAPIBASE() + `/api/v1/video/magazines/`;
  await fetch(url, {
    method: "DELETE",
    headers: getHeader(),
    body: JSON.stringify({ ids: delObjs }),
  })
    .then((res) => {
      ApiResCheck(res);
      return res.json();
    })
    .then((data) => {
      console.log(data);
      onGetMags()
      return data;
    });
}


export async function onGetMags(pg = -1) {
  var page = pg;
  if (pg < 0)
    page = store.state.vm.magPage
  console.log(store.state)
  const limit = store.state.vm.magLimit;
  var skip = limit * page;
  var url = getAPIBASE() + `/api/v1/video/magazines?skip=${skip}&limit=${limit}`;
  await fetch(url, {
    method: "GET",
    headers: getHeader(),
  })
    .then((res) => {
      console.log(res)
      return res.json();
    })
    .then((data) => {
      store.commit("onVmMagazines", data);
      return data;
    });
}


// News Memo update----------------
export async function onMagazineMemoUpdate(memo) {
  console.log(memo)
  store.commit("onMagazineMemoUpdate", memo);
  onMagazineUpdate(store.state.vm.curMag);
}

// News Date update----------------
export async function onMagazineDateUpdate(date) {
  console.log(date)
  store.commit("onMagazineDateUpdate", date);
  onMagazineUpdate(store.state.vm.curMag);
}



// ----------------------------------------------
export async function onDeleteArticle(aId) {
  console.log(" not implemented this function /  delete " + aId)
}

// create ZIP file -------------------------------------------
export async function onDownload(id, imageOut) {
  var obj = null;
  for (var idx in store.state.vm.magazines) {
    if (store.state.vm.magazines[idx].oid == id) {
      obj = store.state.vm.magazines[idx];
      break;
    }
  }
  if (obj == null) {
    alert("no news obj = " + id);
    return;
  }
  //store.commit("onMagazineSelect", obj.id);
  getVmArticles(id).then(() => {
    ExportZip(obj, store.state.vm.articles, imageOut);
  });
}

export async function ExportZip(mag, articles, imageOut) {
  // ------------------
  var newsText = "";
  var memoText = "";
  var picsText = "";
  var files = [];

  // -------------------------------------------------
  function filename2str(fn) {
    var body = fn.split(".")[0];
    var dd = body.split("_");
    var res = body + "," + dd[1];
    return res;
  }

  function urlToPromise(url) {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  // create image list
  //var imgList=[];
  var zip = new JSZip();
  for (var m in articles) {
    var node = articles[m];
    for (var nn in node.images) {
      files.push(node.images[nn].name);
      if (imageOut) {
        // zip image files
        if (!node.images[nn].deleted) {
          zip.file(
            "_pic/" + node.images[nn].name,
            urlToPromise(node.images[nn].url),
            {
              binary: true,
            }
          );
        }
      }
    }
  }

  var lc = 0;
  for (m in articles) {
    node = articles[m];
    // text
    newsText = newsText + node.text + "\n";
    var str = memoText + node.lines[0].text + "\n";
    var strs = str.split("##");
    memoText = memoText + (parseInt(m) + 1) + ")" + strs[1];
    memoText = memoText + node.urls + "\n";
    for (var ll in node.lines) {
      var name = node.lines[ll].pic.name;
      if (name != "") {
        picsText = picsText + lc + "," + filename2str(name) + "\n";
      }
      lc++;
    }
  }
  newsText = newsText.replace(/^\s*[\r\n]/gm, "");
  memoText = memoText.replace(/^\s*[\r\n]/gm, "");

  // create data object for json
  let clone = Object.assign(Object.create(Object.getPrototypeOf(mag)), mag);
  clone.nodes = [];
  for (var cc in articles) {
    clone.nodes.push(articles[cc]);
  }
  var newsJson = JSON.stringify(clone);

  //var text = "zipファイルに含める文字列\nテキストです";
  zip.file("newsExportJson.txt", newsJson);
  zip.file("news.txt", newsText);
  zip.file("memo.txt", memoText);
  zip.file("picDict.txt", picsText);

  // generate download event
  zip.generateAsync({ type: "blob" }).then(function (content) {
    //BlobからオブジェクトURLを作成する
    const url = (window.URL || window.webkitURL).createObjectURL(content);
    //ダウンロード用にリンクを作成する
    const download = document.createElement("a");
    //リンク先に上記で生成したURLを指定する
    download.href = url;
    //download属性にファイル名を指定する
    download.download = mag.date + ".zip";
    //作成したリンクをクリックしてダウンロードを実行する
    download.click();
    //createObjectURLで作成したオブジェクトURLを開放する
    (window.URL || window.webkitURL).revokeObjectURL(url);
  });
}
