
<template template>
	<!-- Import Modal -->
	<button type="button" @click="onNewsList" class="btn bg-gradient-secondary" data-bs-toggle="modal"
		data-bs-target="#importModal">
		Import</button>

	<div class="modal fade importer" id="importModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title mr-4" id="exampleModalLabel">Import ({{ this.importPage }}) </h5>
					<button @click="importPrev" class="btn m-2">
						Prev
					</button>
					<button @click="importNext" class="btn m-2">
						Next
					</button>

					<button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body2">
					<div v-for="(oo, index) in newsList" :key="index">
						<table>
							<tr @click="onImport(oo)">
								<td>
									<img :src="getNewsImage(oo)" />
									<p>{{ oo.date }}</p>
								</td>
								<td>
									<p>{{ getNewsTitle(oo) }}</p>
									<p>{{ getNewsDesc(oo) }}</p>
								</td>
							</tr>
						</table>
						<hr>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
.modal-body2 td {
	margin: 4px;
}

.modal-body2 {
	max-height: 700px;
	overflow-y: auto;
	color: #222;

}


.modal-body2 hr {
	border-color: #222;
}

.modal-body2 img {
	max-height: 200px;
	margin-left: auto;
	margin-right: auto;
}
</style>

<script>
import { newVmArticle } from "../../../RepoVideo";

import { VmArticle } from "../../../videoMag.js";
import {
	getAPIBASE,
	onGetPages,
	getHeader,
	ApiResCheck,
	//onPreviewID,
	//onResetPage,
	//onNextPage,
	//onPrevPage,
} from "../../../Repository";


export default {
	name: "dlg-import",
	components: {
	},

	data() {
		return {
			apibase: getAPIBASE(),
			obj: {},
			date: "",
			importPage: 0,
			newsDate: "",
			okuVND: 0,
			senmanVND: 0,
			suVND: 0,
			simpleVND: 0,
			memo: "",
		};
	},
	computed: {
		newsList() {
			return this.$store.state.pages;
		},

	},
	mounted() {
	},
	methods: {
		onNewsList() {
			console.log("load news")
			onGetPages(this.importPage * 50, 50);
		},
		importPrev() {
			if (this.importPage > 0) {
				this.importPage -= 1;
				onGetPages(this.importPage * 50, 50);
			}
		},
		importNext() {
			this.importPage += 1;
			onGetPages(this.importPage * 50, 50);
		},

		getNewsImg(obj) {
			if (obj.images)
				return obj.images[0];

		},
		getNewsTitle(obj) {
			let desc = this.getDesc(obj);
			for (var ii in desc) {
				if (desc[ii].text.indexOf("http:") < 0) return desc[ii].text;
			}
		},
		getDesc(page) {
			if (page.descJP && page.descJP.length > 0) return page.descJP;
			return page.desc;
		},

		buildArticle(obj) {
			console.log(obj)
			var ac = new VmArticle()
			ac.mag = this.$store.state.vm.curMag._id;
			ac.date = obj.date.split("T")[0];

			console.log(obj.descJP)
			// text
			var txt = "";
			for (let ii in obj.descJP) {
				let ds = obj.descJP[ii];
				console.log(ds.text)
				if (ds.tag == "h1" || ds.tag == "p")
					txt = txt + ds.text + '\n';
			}
			ac.text = txt;
			ac.updateLinesByText(txt);

			// img
			for (let ii in obj.imgs) {
				//let url = this.apibase + `/img/thumb/${obj._id}_${obj.imgs[ii]}`;
				let url = `${obj._id}_${obj.imgs[ii]}`;
				ac.addImage(url, obj.imgs[ii])
			}
			return ac;

		},
		async onImport(obj) {
			var url = getAPIBASE() + `/api/v2/articles/${obj.id}/`;
			await fetch(url, {
				method: "GET",
				headers: getHeader(),
			})
				.then((res) => {
					ApiResCheck(res);
					return res.json();
				})
				.then((data) => {
					console.log(data);
					if (data.desc === undefined)
						data.desc = []
					if (data.descJP === undefined)
						data.descJP = []

					// add
					var ac = this.buildArticle(data)
					var ret = newVmArticle(ac);
					console.log(ret)
					ac._id = ret._id;
					this.$store.commit("onVmArticleAdd", ac);
				});
		},

		getNewsDesc(obj) {
			var cnt = 0;
			let desc = this.getDesc(obj);
			for (var ii in desc) {
				if (desc[ii].text.indexOf("http:") < 0) {
					cnt += 1;
					if (cnt >= 2)
						return desc[ii].text;
				}
			}

		},
		getNewsImage(obj) {
			if (obj.imgs.length > 0)
				return this.apibase + `/img/thumb/${obj.id}_${obj.imgs[0]}`;
		},
	}
}
</script>


