
<template>
  <bank-navi :user="user"></bank-navi>
  <div class="py-0 container-fluid">
    <div class="col-lg-12 col-12">
      <vue-ar-search :apibase="apibase" />
    </div>

    <div class="row">
      <span class="title">記事一覧 <button class="new" @click="newArticle">新規</button></span>
      <div class="row my-4">
        <div class="page-filter col-lg-12 col-md-12 mb-md-0 mb-4">
          <button class="pager button" @click="prevPage">◀ prev</button>
          ページ {{ getPage() }}
          <button class="button pager" @click="nextPage">next ▶</button>
          <label for="checkboxNC">
            <input type="checkbox" id="checkboxNC" v-model="pageFilter.acAll" @change="reload()" />
            All
          </label>

          <label for="checkboxPub">
            <input type="checkbox" id="checkboxPub" v-model="pageFilter.published" @change="reload()" />
            Published
          </label>

          <select @change="onGroup($event)" style="margin-left:10px;">
            <option v-if="checkGroup('')" selected value="">- none -</option>
            <option v-if="!checkGroup('')" value="">- none -</option>

            <template v-for="(tag, index) in groups" :key="index">
              <option v-if="checkGroup(tag)" selected :value="tag">{{ tag }}</option>
              <option v-if="!checkGroup(tag)" :value="tag">{{ tag }}</option>
            </template>
          </select>

          <button @click="clearDate" class="btn-clear">日付クリア</button>
          <label for="newdate">
            <Datepicker @change="filterReload" id="newdate" v-model="pageFilter.date" :locale="locale"
              :disabledTime="true" />
          </label>
          <button @click="filterReload" class="btn-clear" title='date pickerのonchange効かないため'>reload</button>
          <input @change="filterReloadEv($event)" type="radio" id="one" name="dateMode" value="true"
            v-model="pageFilter.isMonth" />
          <label for="one">月</label>
          <input @change="filterReloadEv($event)" type="radio" id="two" name="dateMode" value="false"
            v-model="pageFilter.isMonth" />
          <label for="two">日まで検索</label>

          <button @click="deleteChecked" class="btn-delete" title='チェック済みを削除'>削除</button>

          <div class="filter">
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 mb-md-0 mb-4">
      <table class="pages">
        <tr v-for="(oo, index) in list" :key="index">
          <td>{{ getIndex(index) }}</td>
          <td>
            <a :href="autorunUrl(oo.url)" target="_blank"><img :src="getImg(oo.url)" /></a>
          </td>
          <td style="">
            {{ dateJP(oo.date) }}<br />
            <a v-if="!oo.published" class="btn publish" href="#" type="button" @click="onPublishToggle">draft</a>
            <a v-if="oo.published" class="btn publish published" href="#" type="button"
              @click="onPublishToggle">Published!
            </a>
            {{ dateJP(oo.createdAt) }}<br />
          </td>
          <td @click="preview(index)">
            <img class="thumb" v-if="isThumb(oo)" :src="getThumb(oo)" height="50" />
            <a />
          </td>
          <td> <input @click="delCheck(index)" type="checkbox" class="delac" /> </td>
          <td @click="preview(index)">{{ oo.types }}</td>
          <td @click="preview(index)">{{ oo.desc_letters }}</td>
          <td @click="preview(index)">{{ getTitle(oo) }}</td>
          <td>
            <a href="#" @click="summarize(oo.id, index)"><span class="is-gpt" v-if="noSum(oo)">要約</span></a>
            <span class="is-gpt" v-if="oo.sumFlag">[o]</span>
          </td>
          <td>
            <button @click="runACJP(oo.url, false)" class="btn url">
              JP
            </button>
            <a class="btn url" :href="oo.url" type="button" target="_blank">site</a>

            <a v-if="oo.published" class="btn url vpress" :href="vpressUrl(oo)" target="_blank">vpress</a>
          </td>
          <td>
            <a v-if="oo.tweeted" class="btn url tweeted" href="https://twitter.com/VPressStudio"
              target="_blank">tweeted<br>{{ oo.tweet_cnt }}</a>
          </td>
        </tr>
      </table>
    </div>
    <a class="btn bg-gradient-success mt-1" href="#" type="button" @click="back">もどる</a>
  </div>
</template>
<script>
export var logoImages = [
  ["baodanang"],
  ["hanoitimes"],
  ["thesaigontimes"],
  ["thanhnien"],
  ["vnexpress"],
  ["vneconomy"],
  ["dantri"],
  ["laodong"],
  ["vietnamnews"],
  ["vietnamnet"],
  ["vietnaminsider"],
  ["tuoitre"],
  ["markettimes"],
  ["cafef"],
  ["vietnamplus"],
  ["qdnd"],
  ["qrt"],
  ["doanhnhan"],
  ["saigoneer"],
  ["vinlove"],
  ["bnews"],
  ["tienphong"],
];

import VueArSearch from "@/components/VueArSearch.vue";
import BankNavi from "@/components/UrlbankNavi.vue";
import Datepicker from "vue3-datepicker";
import "vue3-datepicker/dist/vue3-datepicker.css";

import {
  getAPIBASE,
  onGetPages,
  onPreviewID,
  onResetPage,
  onNextPage,
  onPrevPage,
  DeleteArticles,
  onNewArticle,
  onSummarizeAPI,
} from "../Repository";
//import { tSConstructSignatureDeclaration } from "@babel/types";
export default {
  name: "Pages",
  components: {
    Datepicker,
    BankNavi,
    VueArSearch,
  },

  data() {
    return {
      delAry: [],
      limit: 25,
      render: 0,
      pageFilter: {
        acAll: true,
        published: false,
        date: "",
        isMonth: "true",
        group: "",
      },
      apibase: getAPIBASE(),
    };
  },
  computed: {
    groups() {
      return this.$store.state.groups;
    },
    selectedGroup() {
      return this.$store.state.selGroup;
    },
    user() {
      return this.$store.state.mail;
    },
    list() {
      return this.$store.state.pages;
    },
  },
  updated() { },
  mounted() {
    this.pageFilter = this.$store.state.pageFilter;
    this.reload();
  },
  methods: {
    encodeTranslateUrl(url) {
      let domain = (new URL(url));
      let url1 = domain.origin;
      url1 = url1.replaceAll("-", "--");
      url1 = url1.replaceAll(".", "-");
      let url2 = domain.pathname;
      return url1 + '.translate.goog' + url2 + '?_x_tr_sl=auto&_x_tr_tl=ja&_x_tr_hl=ja&_x_tr_pto=wapp';
    },
    runACJP(url, flg) {
      let url2 = this.encodeTranslateUrl(url);
      if (flg) url2 = url2 + "#autorunner";
      else url2 = url2 + "#noauto";
      var ww = window.open(url2, "capturejp" + this.cnt);
      ww.resizeTo(900, 750);
      if (flg) {
        setInterval(() => {
          ww.close();
        }, 90000);
      }
    },

    summarize(id, index) {
      console.log(id)
      this.$store.commit("onPageListSumFlag", index)
      onSummarizeAPI(id)
    },
    noSum(obj) {
      //if (obj.sumFlag)
      //  return false
      if ((obj.sumshort) && (obj.sumshort != ""))
        return false;
      return true;
    },
    newArticle() {
      onNewArticle()
    },
    deleteChecked() {
      if (this.delAry.length == 0) {
        alert("削除したい記事を〆チェックしてください")
        return;
      }
      if (!confirm("チェックした記事を削除します\nよろしいですか？"))
        return
      var checkboxes = document.getElementsByClassName("delac");
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == "checkbox") {
          checkboxes[i].checked = false;
        }
      }

      DeleteArticles(this.delAry)
      this.filterReload()
      this.delAry = []
    },
    delIndex(idx) {
      for (var ii = 0; ii < this.delAry.length; ii++) {
        if (idx == this.delAry[ii]) {
          delete this.delAry[ii]
        }
      }

    },
    delCheck(idx) {
      if (this.delAry.includes(idx))
        this.delIndex(idx);
      else
        this.delAry.push(idx);
      console.log(this.delAry)
    },
    onGroup(ev) {
      console.log(ev.target.value)
      this.pageFilter.group = ev.target.value
      this.$store.commit("onGroupFilter", ev.target.value)
      this.reload();
    },
    checkGroup(gr) {
      return (gr == this.$store.state.pageFilter.group);
    },
    clearDate() {
      this.pageFilter.date = "";
      this.filterReload()
    },
    onFilterDate() { },
    autorunUrl(url) {
      return url; // + "#autorunner";
    },
    vpressUrl(oo) {
      return "https://www.vpress.asia/article/" + oo.id + "/";
    },
    getShortDate(date) {
      let dt = new Date(date);
      console.log(dt);

      return dt.toLocaleDateString("ja");
    },
    dateJP(isodate) {
      if (!isodate) return "";
      var dt = isodate.split("T")[0].split("-");
      return `${dt[0]} ${dt[1]}/${dt[2]}`;
    },
    getTitle(page) {
      let desc = this.getDesc(page);
      for (var ii in desc) {
        if (desc[ii].text.indexOf("http:") < 0) return desc[ii].text;
      }
    },
    getDesc(page) {
      //  api がdescJP だけ返しているので現状意味ないです
      if (page.descJP && page.descJP.length > 0 && page.descJP[0].text.length > 0) {
        return page.descJP;
      }
      return page.desc;
    },
    isThumb(oo) {
      if (!oo.imgs) return false;
      return oo.imgs.length > 0;
    },
    getThumb(obj) {
      if (obj.imgs && obj.imgs.length > 0)
        return this.apibase + `/img/thumb/${obj.id}_${obj.imgs[0]}`;
      return "";
    },
    getIndex(idx) {
      return this.$store.state.arPage * this.limit + idx;
    },
    getImg(url) {
      if (!url) {
        return "";
      }
      for (var i in logoImages) {
        if (url.indexOf(logoImages[i][0]) >= 0)
          return "/logo/" + logoImages[i][0] + ".png";
      }
      return "";
    },
    getPage() {
      return this.$store.state.arPage;
    },
    getPubMode() {
      let pubmode = "all";
      if (!this.pageFilter.acAll) {
        if (this.pageFilter.published) pubmode = "published";
        else pubmode = "draft";
      }
      return pubmode;
    },
    filterReloadEv(ev) {
      console.log(ev)
      console.log("filter reload ")
      onResetPage()
      this.reload()
    },

    filterReload() {
      console.log("filter reload ")
      onResetPage()
      this.reload()
    },
    reload() {
      let pubmode = this.getPubMode();
      let start = "";
      let end = "";
      if (this.pageFilter.date != "") {
        if (this.pageFilter.isMonth == "true") {
          let st = new Date(this.pageFilter.date);
          start = new Date(st.getFullYear(), st.getMonth(), 1);
          start = start.addHours(7);
          end = new Date(st.getFullYear(), st.getMonth() + 1, 0);
          end = end.addHours(7 + 24);
        } else {
          start = new Date(this.pageFilter.date);
          start = start.addHours(7);
          end = new Date(this.pageFilter.date);
          end = end.addHours(7 + 24);
        }
      }

      onGetPages(
        this.$store.state.arPage,
        this.limit,
        pubmode,
        this.getDateStr(start),
        this.getDateStr(end),
        this.pageFilter.group
      );
    },
    getDateStr(dt) {
      if (dt == "") return dt;
      var d = new Date(dt);
      return d.toISOString().substring(0, 10);
    },
    nextPage() {
      //this.page += 1;
      onNextPage();
      this.reload();
      //this.$store.commit("onArPageNext");
    },
    prevPage() {
      onPrevPage();
      this.reload();
      //this.$store.commit("onArPagePrev");
      //onArPagePrev();
    },
    branch(url) {
      this.$store.commit("onPageFilter", this.pageFilter);
      this.$router.push(url);
    },
    preview(idx) {
      this.$store.commit("onPageFilter", this.pageFilter);
      onPreviewID(this.$store.state.pages[idx].id);
      //console.log(idx);
      //onPreview(idx);
      //this.$router.push("/Page");
    },
  },
};
</script>


<style src="./Pages.css"></style>
