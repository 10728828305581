<style>
button.btn.auto-capture {
  background-color: rgb(252, 145, 143) !important;
  color: #fff;
  margin: 0;
  margin-left: 40px !important;
  font-size: 1em !important;
}

button.btn.toData {
  color: #fff;
  background-color: rgb(36, 70, 133) !important;
  margin-right: 80px;
  margin-left: 80px;
  font-size: 1em !important;
}

td {
  vertical-align: middle;
}

.month-node {
  background-color: rgb(167, 217, 147);
  float: left;
  border: 2px solid rgb(159, 196, 172);
  padding: 5px 10px;
  margin: 10px 20px;
  color: #111;
  font-size: 1.2em;
  border-radius: 12%;
}

button#autoBtn.autoRunner {
  background-color: rgb(102, 143, 208) !important;
  color: #f00 !important;
}

.month-node:hover {
  background-color: rgb(191, 241, 171);
  font-weight: bold;
}

button.btnx {
  padding: 8px 18px !important;
  font-size: 0.7em !important;
  background-color: #cceccc;
}

button.pager:hover {
  border: solid 3px #02ff4ea5;
}

button.pager {
  border-radius: 40%;
  border: solid 3px #e6e1e170;
}

button.enable {
  border: solid 2px #00ff00 !important;
  color: #02ff4ea5;
}

button.disable {}

button.url-mode:hover {
  color: #02ff4ea5;
}

table.url tr:hover {
  background-color: #404b56 !important;
}

button.url-mode {
  margin-left: 5px;
  border: solid 2px #5e6a5d;
  background-color: #2d3741 !important;
  color: #eee;
}

span.title {
  font-size: 1.6em;
  font-weight: bold;
  margin: 3px 10px;
}

table.url.pages tr {
  border-bottom: solid 1px #888;
  background-color: #404b56 !important;
}

table.url button.btnrun {
  border: solid 1px #989f97;
  border-radius: 12%;
  padding: 0 4px;
  background-color: #bcd2d6;
}

button.listbox {
  border: solid 1px #989f97;
  font-size: 1.1em;
  border-radius: 12%;
  padding: 3px 18px;
  margin-left: 20px;
  color: #eee;
  background-color: #8cabb6;
}

table.url button.btndel {
  border: solid 1px #989f97;
  border-radius: 12%;
  padding: 0 4px;
  background-color: #d6bccb;
}

table.url th {
  border-bottom: 1px rgb(148, 148, 148) solid;
  background-color: #5d4e4e;
}

div.selSite {
  border: solid 1px #989f97;
  border-radius: 6px;
  padding: 14px;
  margin: 8px;
}

div.selSite p:hover {
  background-color: #74636d;
}

div.selSite p {
  padding: 4px;
  margin: 0px;
  width: 100%;
}

.selSite a {
  width: 100%;
  color: #eee;
}

.selSite img {
  height: 30px;
}

img.logo {
  min-height: 24px;
}

table.url th {
  text-align: center !important;
  border: solid 1px #777;
}

table.url th.htop {
  background-color: #372929;
}
</style>

<template>
  <bank-navi :user="user"></bank-navi>
  <div class="py-0 container-fluid">
    <div class="col-lg-12 col-12">
      <vue-search :apibase="apibase" />
    </div>
    <span class="title">Daily Info</span>

    <div class="row">
      <div class="row my-4">
        <div class="col-lg-12col-md-12 mb-md-0 mb-4">
          <button class="button green pager" @click="prevPage">prev ◀</button>
          ページ {{ getPage() }}
          <button class="button green pager" @click="nextPage">▶ next</button>
          <div class="col-lg-12col-md-12 mb-md-0 mb-4">
            <div v-if="linkBox" class="selSite">
              <h3>site list</h3>
              <p v-for="(nn, index) in sites" :key="index">
                <a class="" :href="fullUrl(nn.url)" target="_blank">
                  <img :src="getImg(nn.url)" />
                  {{ nn.url }}
                </a>
                <br />
              </p>
            </div>

            <table class="url">
              <tr>
                <th class="htop"></th>
                <th class="htop" colspan="3">AQI</th>
                <th class="htop" colspan="3">Weather</th>
                <th class="htop" colspan="6">Market</th>
              </tr>
              <tr>
                <th class="">date</th>
                <th>hanoi</th>
                <th>danan</th>
                <th>HCMC</th>
                <th>hanoi</th>
                <th>danan</th>
                <th>HCMC</th>
                <th></th>
              </tr>

              <tr v-for="(oo, index) in list" :key="index">
                <td>
                  {{ shortDate(oo.date) }}<br>
                  {{ getTime(oo["date-utc"]) }}
                </td>

                <td v-for="(aqi, index2) in oo.AQI" :key="index2">
                  {{ aqi.aqi }}
                </td>
                <td v-for="(ww, index3) in oo.Weather" :key="index3">
                  {{ ww.id }} : {{ ww.details }}<br>
                  {{ ww.max }} / {{ ww.min }} : uv={{ ww.uvi }}
                </td>
                <td>
                  <span v-for="(mk, index4) in oo.Market" :key="index4">
                    <span v-if="mk.id == 'VNINDEX'">
                      {{ mk.id }} = {{ shortVal(mk.val, 4) }} <br>
                    </span>
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BankNavi from "@/components/UrlbankNavi.vue";
import VueSearch from "@/components/VueSearch.vue";
import {
  getSiteData,
  getAPIBASE,
  onGetDaily,
} from "../Repository";
export default {
  name: "Urls",
  components: {
    VueSearch,
    BankNavi,
  },
  data() {
    return {
      linkBox: false,
      urlmode: "normal",
      stopCapture: false,
      runner: false,
      page: 0,
      pageRej: 0,
      pageCap: 0,
      pageErr: 0,
      noCaptured: true,
      render: 0,
      cnt: 0,
      apibase: getAPIBASE(),
    };
  },
  computed: {
    sites() {
      return this.$store.state.siteData;
    },
    getCaptureBtnStr() {
      if (this.runner) {
        return `取り込み中(${this.cnt})`;
      } else {
        return "自動取り込み開始";
      }
    },
    counter() {
      return this.cnt;
    },
    user() {
      return this.$store.state.mail;
    },
    list() {
      return this.$store.state.dailyList;
    },
  },
  updated() { },
  mounted() {
    this.loadUrls();
    getSiteData();
  },
  methods: {
    shortVal(f, keta) {
      return f.toFixed(keta)
    },
    shortDate(date) {
      return date.split("T")[0]
    },
    getTime(date) {
      //console.log(date)
      var localDt = new Date(date);
      let h = localDt.getHours();
      let m = localDt.getMinutes();
      return `${h}:${m}`
    },

    getPage() {
      var page = this.page;
      if (this.urlmode == "captured") {
        page = this.pageCap;
      }
      if (this.urlmode == "rejected") {
        page = this.pageRej;
      }

      if (this.urlmode == "unfinished") {
        page = this.pageErr;
      }
      return page;
    },

    modeClass(mode) {
      return mode == this.urlmode;
      //  if (mode == this.urlmode) return "enable";
      //  return "disable";
    },
    loadUrls() {
      onGetDaily();
    },

    reload() {
      this.cnt = 0;
      this.loadUrls();
    },
    nextPage() {
      this.cnt = 0;
      this.page += 1;
      this.loadUrls();
    },
    prevPage() {
      this.cnt = 0;
      if (this.page == 0) return;
      this.page -= 1;
      this.loadUrls();
    },
    branch(url) {
      this.$router.push(url);
    },
  },
};
</script>
