
<style>
.dlg-frame .selected {
  background-color: rgb(204, 0, 153);
}

.tweet-dt {
  color: #333;
}

.dlg-frame table td p {
  line-height: 18px;
}

img.btn-enabled {}

img.btn-disabled {
  filter: brightness(50%);
}


table span.min {
  color: #fff;
  width: 26px;
  height: 23px;
  text-align: center;
  padding: 0;
  margin: 0px 2px;
  display: inline-block;
  border: solid 1px #164228;
  border-radius: 5px;
  font-size: 0.8em;
}

table span.past {
  color: #ccc;
  background-color: #666;
}

table span.empty {
  background-color: #7edca5;
}

table span.reserved {
  background-color: #4f6d5b;
}

.dlg-frame .title {
  color: #000;
}

td.lock_edit {
  width: 80px;
}

table.table.page tr {
  width: 100% !important;
}

table.page {
  width: 850px !important;
  background-color: #fff;
  color: #000;
  border-top: 4px #443a32 solid;
  border-bottom: 8px #887260 solid;
  table-layout: fixed;
}

table.page td {
  white-space: unset;
  margin: 4px;
  padding: 6px;
}

table.page p {
  width: 100%;
  overflow-wrap: break-word;
}


button.btn {
  padding: 8px 18px !important;
  font-size: 0.7em !important;
  background-color: #a7b0a7;
}

h1.desc {
  display: block;
  font-size: 1.8em;
  padding: 14px;
  font-weight: bold;
}

h2.desc {
  display: block;
  font-weight: bold;
  font-size: 1.3em;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 0;
  margin-right: 0;
}

h3.desc {
  display: block;
  font-size: 1.2em;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h4.desc {
  display: block;
  font-size: 1.2em;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

p.desc {
  display: block;
  font-size: 1.1em;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 0;
  margin-right: 0;
}

table.page td {
  border-bottom: 1px dotted #ccc;
}

table.page td:nth-of-type(1) {
  background-color: #243a44;
  text-align: center;
}

.page-header2 {
  background-color: #3f92a7;
  padding: 10px;
  color: #fff;
  margin-bottom: 20px !important;
}

table.page.page img {
  max-height: 500px;
}

table.page iframe.youtube {
  width: 960px;
  height: 540px;
}

a.btn-edit {
  width: 60px !important;
  background-image: linear-gradient(310deg, #ad17a5 0%, #ec2de6 100%);
  margin: 0;
  padding: 8px 4px;
}

table.header {
  padding: 18px;
}


table.header td:nth-child(1) {
  color: #ccc;
}


table.header td:nth-child(2) {
  max-width: 110px;
  margin: 4px 8px;
  padding: 0px 8px;
  color: #8cf;
  width: 100px;
}

.header a.published {
  color: rgb(29, 107, 0) !important;
  background-color: #9dd16c !important;
  text-transform: none !important;
}

.header a.publish {
  text-transform: none !important;
  background-color: #a0875e;
  color: rgb(225, 221, 221);
  padding: 0px;
  width: 100px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Horizontal align */
  font-size: 18px;
}

table.page pre {
  max-width: 800px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
  margin: 0px;
}

.summarize {
  max-width: 900px;
  min-height: 40px;
  border-bottom: solid 1px #666;
}

.page p.desc {
  max-width: 800px;
  height: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.fit-center {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

a.url {
  width: 300px;
  color: #ccc;
  padding: 6px;
  background-color: #a06c9e;
}

.header a.tweeted {
  color: rgb(255, 255, 255) !important;
  background-color: #3a8989 !important;
  text-transform: none !important;
  width: 60px;
  margin: 0;
  border: solid 1px #842;
  min-width: 80px;
  margin-top: -10px;
  margin-left: 10px;
  padding: 0px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7em;
}


.header a.tweet {
  color: rgb(255, 255, 255) !important;
  background-color: #32d3ec !important;
  text-transform: none !important;
  width: 60px;
  margin: 0;
  margin-top: -10px;
  margin-left: 10px;
  padding: 0px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.dlg-frame textarea {
  min-width: 600px;
  min-height: 150px;
  margin: 0px;
  padding: 0;

}

div.time-select {
  max-height: 500px;
  overflow: scroll;
}


.dlg-frame table {
  width: 100%;
}

.dlg-frame table td:nth-child(1) {
  width: 220px;
}



.dlg-frame table tr:nth-child(4) td {
  vertical-align: top;
}
</style>

<template>
  <bank-navi :user="user"></bank-navi>
  <div class="py-0 container-fluid">
    <a class="btn bg-gradient-success mt-1 mx-1" href="#" type="button" @click="back">もどる</a>
    <a class="btn bg-gradient-warning mt-1 mx-1" href="#" type="button" @click="reloadPage">reload</a>
    <span id="err-msg" class="error"></span>
    <span class="text-orange-50" id="tweet-result"></span>
    <div class="row my-0">
      <div class="page-header2 col-lg-12 col-md-12">
        <table class="header">
          <tr>
            <td :title="obj.date">

              <img :src="getImg(obj.url)" width="60" /><br>
              {{ dateJP(obj.date) }}
            </td>
            <td class="m-8">{{ obj.types }} </td>

            <td>
              <a v-if="!obj.published" class="btn publish" href="#" type="button" @click="onPublishToggle">draft</a>
              <a v-if="obj.published" class="btn publish published" href="#" type="button" @click="unlock">Published!

              </a><br>
              <template v-if="obj.published">
                <a v-if="!obj.tweeted" :title=obj.tweet_cnt class="btn tweet" href="#" type="button"
                  @click="onDialog">Tweet</a>
                <a v-if="obj.tweeted" class="btn tweeted " href="#" type="button" @click="onDialog">Tweeted
                  {{ obj.tweet_cnt }}</a>
              </template>

            </td>
            <td class="lock_edit">
              <div class="fit-center">
                <a v-if="obj.published" @click="unlock"><img src="/img/lock_icon.svg" width="32"></a>

                <a v-if="!obj.published" class="btn btn-edit bg-gradient-success mt-1" href="#" type="button"
                  @click="goEdit">編集</a>
              </div>
            </td>
            <td>
              {{ obj._id }}<br>
              <a :href="obj.url" target="_blank">
                {{ obj.url }}
              </a><br>
              <span>
                <a v-if="obj.published" class="btn url vpress" :href="vpressUrl(obj)" target="_blank">vpress
                  / {{ obj._id }}
                </a>
              </span>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-lg-12col-md-12 mb-md-0 mb-4">
        <table class="func" style="width:200px;">
          <tr>
            <td>
              <a href="#" class="btn btn-mode" @click="toggleMode">{{ mode }}</a>
            </td>
            <td>
              <a href="#" class="btn btn-mode" @click="copyDesc">ﾛ Org</a>
            </td>
          </tr>
        </table>
        <p class="summarize">要約：{{ obj.summarize }}</p>
        <p class="summarize">sns：{{ obj.sumshort }}</p>
        <table class="page" style="width: 80%">
          <tr v-for="(oo, index) in   desc" :key="index">
            <td width="30px">{{ oo.tag }}</td>
            <td width="">
              <div v-if="oo.tag == 'img'">
                <img :src="getImgUrlByText(oo.text)" height="120" />
              </div>
              <div v-if="oo.tag == 'iframe'">
                <iframe :src="oo.text"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="" frameborder="0" scrolling="no" title="YouTube video player" class="youtube"></iframe>
              </div>
              <template v-if="oo.tag == 'h1'">
                <h1 :class="getClass(oo.tag)">{{ longText(oo.text) }}</h1>
              </template>
              <template v-if="oo.tag == 'h2'">
                <h2 :class="getClass(oo.tag)">{{ longText(oo.text) }}</h2>
              </template>
              <tempalte v-if="oo.tag == 'h3'">
                <h3 :class="getClass(oo.tag)">{{ longText(oo.text) }}</h3>
              </tempalte>
              <template v-if="oo.tag == 'p'">
                <pre :class="getClass(oo.tag)">{{ longText(oo.text) }}</pre>
              </template>
              <template v-if="oo.tag == 'span'">
                <pre :class="getClass(oo.tag)">{{ longText(oo.text) }}</pre>
              </template>
              <template v-if="oo.tag == 'div'">
                <pre :class="getClass(oo.tag)">{{ longText(oo.text) }}</pre>
              </template>
              <template v-if="oo.tag == 'font'">
                <pre :class="getClass(oo.tag)">{{ longText(oo.text) }}</pre>
              </template>

            </td>
          </tr>
        </table>
      </div>
    </div>
    <a class="btn bg-gradient-success mt-1" href="#" type="button" @click="back">もどる</a>
  </div>


  <GDialog v-model="dialogState">
    <div class="dlg-frame">
      <h3>Tweet予約</h3>
      <table :key="render">
        <tr>
          <td rowspan="6">
            <div class="time-select">
              <p class="title" :title="dummy">予約時刻 {{ dateJP(toIsoString(tweet_date)) }}</p>
              <p class="hh" v-for=" hh  in  hRange(0) " :key="hh">
                {{ get02d(hh) }}
                <span class="min" :class="getMinClass(0, hh, mm)" @click="onSelTime($event, 0, hh, mm)"
                  v-for=" mm  in  minRange " :key="mm">{{ get02d(mm)
                  }}</span>
              </p>
              <p>翌日</p>
              <p class="hh" v-for=" hh  in  hRange(1) " :key="hh">
                {{ get02d(hh) }}
                <span class="min" :class="getMinClass(1, hh, mm)" @click="onSelTime($event, 1, hh, mm)"
                  v-for=" mm  in  minRange " :key="mm">{{ get02d(mm)
                  }}</span>
              </p>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <label for="newdate"> <br>
              <VueDatePicker @change="filterReload" id="newdate" v-model="tweet_date" :locale="locale"
                :enable-time-picker=true :seconds-increment="10" :is-24=true /><br>
            </label>
            <span class="tweet-dt">投稿 => {{ getDtJP(tweet_post_date) }}</span>
          </td>
        </tr>
        <tr>
          <!--
          <td width>
            <select v-model="title_emoji">
              <option v-for=" ee  in  emojis " :key="ee" :value="ee">{{ ee }}</option>
            </select>
            <span class="ml-10 ">
              {{ title_emoji }}
            </span>
            ※LINEは１日に１０回を目安にし、比較的楽しい＆重要なものだけ配信します
          </td>
          -->
          <td>
            <img @click="flipLINE()" src="/img/line80.png" :class="{
              'btn-enabled': tglLine, 'btn-disabled': !tglLine
            }" width="32">

            <a @click="tglLINE()">
            </a>
          </td>
        </tr>
        <!--
        <tr>
          <td>
            <textarea class="title" v-model="title" @change="onInput"></textarea>
          </td>
        </tr>
        -->
        <tr>
          <td>
            <textarea v-model="sumshort" @change="onInput"></textarea>
          </td>
        </tr>
        <tr>
          <td>
            <p> https://www.vperss.asia/{{ obj._id }}/ </p>
            <p class="letters">文字数:{{ sumLen }} / 170</p>
            <button v-if="sumLen < 170" @click="onTweet">投稿</button>
            <button @click="dialogState = false">キャンセル</button>

          </td>
        </tr>
      </table>
    </div>
  </GDialog>
</template>
<script>
import { onPreviewID, onTweetReservations, onTweetAPI, getAPIBASE, onUpdatePublished, onUnlockAC, getDescriptionText } from "../Repository";
import BankNavi from "@/components/UrlbankNavi.vue";
import { logoImages } from "./Pages";
import { GDialog } from 'gitart-vue-dialog'
export default {
  name: "Page",
  components: {
    BankNavi,
    GDialog,
  },

  data() {
    return {
      dialogState: false,
      emojis: [
        "",
        "🪷",
        "✈️",
        "🌴",
        "👮‍♀️",
        "👨‍✈️",
        "🧑‍🍳",
        "️🏥",
        "⛑️",
        "👩‍💻",
        "🧑‍🍳",
        "⚽",
        "️🍔",
        "🥭",
        "🚲",
        "🛵",
        "🚃",
        "🇯🇵 ",
        "🇰🇷",
        "🇵🇭 ",
        "🇲🇾",
        "🇸🇬",
        "🇵🇱",
        "🇺🇸",
        "🍃",
        "🎄",

      ],
      //hourDiv: 6,
      //minbase: 10,
      title: "",
      title_emoji: "🪷",
      minRange: [...Array(6).keys()].map((num) => num * 10),
      sumLen: 0,
      sumshort: "",
      page: 0,
      tweet_date: "",
      tweet_post_date: "",
      noCaptured: true,
      apibase: getAPIBASE(),
      render: 0,
      tglLine: false,
    };
  },
  computed: {
    dummy() {
      return this.$store.state.tw_reservations.length;
    },
    obj() {
      return this.$store.state.preview;
    },
    desc() {
      if (this.$store.state.edit.mode == "org")
        return this.$store.state.preview.desc;
      return this.$store.state.preview.descJP;
    },
    /*
     desc() {
       var page = this.$store.state.preview;
       if (page.descJP.length > 0) return page.descJP;
       return page.desc;
     },*/
    mode() {
      return this.$store.state.edit.mode;
    },
    user() {
      return this.$store.state.mail;
    },
  },
  updated() { },
  mounted() {
    //console.log(this.$store.state.preview);
    this.tweet_date = new Date();
    this.tweet_post_date = new Date();
    //if (this.state.preview.descJP && this.state.preview.descJP.length > 0)
    this.sumshort = this.$store.state.preview.sumshort;
    this.onInput()
    if (this.$store.state.preview.descJP && this.$store.state.preview.descJP.length > 0)
      this.title = this.$store.state.preview.descJP[0].text;
    else
      this.title = this.$store.state.preview.desc[0].text;

  },
  methods: {
    flipLINE() {
      this.tglLine = !this.tglLine;
    },
    lineClass() {
      if (this.tglLine)
        return "btn-enabled";
      return "btn-disabled";
    },
    hRange(dayOfs) {
      var now = new Date();
      var dt = new Date(this.tweet_date);
      dt = new Date(dt.getTime() + (dayOfs * 24 * 60 * 60 * 1000));  // n day later
      if ((now.getYear() == dt.getYear()) && (now.getMonth() == dt.getMonth()) && (now.getDay() == dt.getDay())) {
        let ary = [];
        for (var h = dt.getHours(); h < 22; h++)
          ary.push(h);
        return ary;
      }
      return [...Array(17).keys()].map((num) => 5 + num);
    },

    onDialog() {
      onTweetReservations();
      this.dialogState = true;
    },
    reloadPage() {
      console.log(this.$store.state.preview._id);
      onPreviewID(this.$store.state.preview._id);
    },
    getDtJP(dt) {
      let now = new Date(dt)
      const year = now.getFullYear();
      const month = now.getMonth() + 1; // 月は0から始まるため、1を足す
      const date = now.getDate();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      // 日本式の形式にフォーマット
      const formattedDateTime = `${year}年${month}月${date}日 ${hours}:${minutes}:${seconds}秒`;

      return (formattedDateTime); // "2023年4月17日 12時30分45秒" のように出力される
    },
    getMinClass(dayOfs, hh, mm) {
      let now = new Date()
      let ss = new Date(this.tweet_date);
      ss = new Date(ss.getTime() + (dayOfs * 24 * 60 * 60 * 1000));  // n day later

      ss.setHours(parseInt(hh), parseInt(mm), 0, 0);
      let ee = new Date(ss.getTime() + (10 * 60 * 1000));
      if (ss <= now) {
        return "past"
      }
      let s1 = ss.getTime();
      let e1 = ee.getTime();
      for (var ii in this.$store.state.tw_reservations) {
        let dt = new Date(this.$store.state.tw_reservations[ii]);
        let d1 = dt.getTime();
        if (d1 >= s1 && (dt < e1)) {
          return 'reserved';
        }
      }
      return "empty"
    },
    get02d(m) {
      let mm = m.toString()
      if (mm.length < 2)
        return "0" + mm;
      return mm;
    },
    onInput() {
      if (!this.sumshort)
        this.sumshort = ""
      //this.sumLen = this.title.length + this.sumshort.length + 25;
      this.sumLen = this.sumshort.length + 20;

    },
    onTweet() {
      //let text = this.title_emoji + this.title + "\n" + this.sumshort;
      let text = this.sumshort;
      console.log(text);
      if (text.length + 25 > 170) {
        if (!confirm("文字数(170)オーバーです\n\nこのまま実行しますか？" + text + "\n\n" + text.length + 25 + "文字"))
          return;
      }
      onTweetAPI(this.$store.state.preview._id, this.tweet_post_date, text, this.tglLine);
      this.dialogState = false;
      onTweetReservations();
    },
    toIsoString(date) {
      var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
          return (num < 10 ? '0' : '') + num;
        };

      return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
    },

    onSelTime(event, dayOfs, hh, mm) {
      const elements = document.querySelectorAll(".min"); // class="min"のすべての要素を取得
      elements.forEach(element => {
        element.classList.remove("selected"); // 各要素から"class"が"selected"のクラスを削除
      });

      var dt = new Date(this.tweet_date);
      dt = new Date(dt.getTime() + (dayOfs * 24 * 60 * 60 * 1000));  // n day later
      dt.setHours(parseInt(hh), parseInt(mm), 0, 0);
      if (dt < new Date())
        return;

      //console.log(`${hh}:${mm}`)
      this.tweet_post_date = dt.toString()
      //console.log(this.tweet_date);
      //console.log(this.toIsoString(new Date(this.tweet_date)));
      event.target.classList.add("selected")
    },

    copyDesc() {
      // テキストを直接指定する場合
      navigator.clipboard.writeText(getDescriptionText());
      //console.log("copy desc to clipboard")
    },
    onPublishToggle() {
      if (confirm("公開状態を変更します")) {
        onUpdatePublished();
        //this.$store.commit("onEditPublish");
      }
    },
    unlock() {
      if (confirm("記事をdraft状態に戻します。ビルド後はサイトで表示されなくなります"))
        onUnlockAC();
    },
    longText(text) {
      //不具合のため一旦。
      return text;
      /*
    //複数のスペースをまとめ
      let str = text.replace(/(\s|&nbsp;)+/g, " ");
      //
      if (str.length < 200) return str;
     
      let last = "";
      let res = "";
      for (var i = 0; i < str.length; i++) {
        let c = str.charAt(i);
        res = res + c;
        if (c == "." && !(last >= "0" && last <= "9")) {
          res = res + "\n\n";
        }
        last = c;
      }
      return res; //str.replaceAll(".", ".\n\n");
      */
    },
    toggleMode() {
      if ("org" == this.$store.state.edit.mode) this.setMode("ja");
      else this.setMode("org");
    },
    setMode(md) {
      this.$store.commit("onEditMode", md);
    },

    dateJP(isodate) {
      var od = isodate.split("T")[0];
      var dt = od.split("-");
      return `${dt[0]}/${dt[1]}/${dt[2]}`;
    },
    dtJP(isodate) {
      var od = isodate.split("T")[0];
      var dt = od.split("-");
      return `${dt[0]}/${dt[1]}/${dt[2]}`;
    },

    getTitle(page) {
      let desc = this.getDesc(page);
      for (var ii in desc) {
        if (desc[ii].text.indexOf("http:") < 0) return desc[ii].text;
      }
    },
    getDesc(page) {
      if (this.state.preview.descJP && this.state.preview.descJP.length > 0)
        return page.descJP;
      return page.desc;
    },

    getImgUrl(fn) {
      let obj = this.$store.state.preview;
      let id = "";
      if (obj.id) id = obj.id;
      else id = obj._id;
      return this.apibase + `/img/thumb/${id}_${fn}`;
    },
    getFNameByText(txt) {
      for (var ii = 0; ii < this.$store.state.preview.imgs.length; ii++) {
        var fn = this.$store.state.preview.imgs[ii]
        if (txt.indexOf(fn) >= 0)
          return fn;
      }
    },
    getImgUrlByText(text) {
      //var fn = this.getFNameByText(text)
      let obj = this.$store.state.preview;
      let id = "";
      if (obj.id) id = obj.id;
      else id = obj._id;
      return this.apibase + `/img/thumb/${id}_${text}`;
    },
    vpressUrl(oo) {
      return "https://www.vpress.asia/article/" + oo._id + "/";
    },

    getPageImg(url) {
      var obj = this.$store.state.preview;
      for (let i in obj.imgUrls) {
        if (obj.imgUrls[i] == url) return this.getImgUrl(obj.imgs[i]);
      }
      return "";
    },
    getClass(tag) {
      return tag + "-tag desc";
    },
    getImg(url) {
      if (!url) {
        return "";
      }
      for (var i in logoImages) {
        if (url.indexOf(logoImages[i][0]) >= 0)
          return "/logo/" + logoImages[i][0] + ".png";
      }
      return "";
    },
    getPage() {
      return this.page;
    },
    goEdit() {
      this.$router.push("/page/edit");
    },

    back() {
      this.$router.push("/Pages");
    },
  },
};
</script>
