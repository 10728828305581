import { onGetMags, onMagazineCreate } from "../../RepoVideo";
import NewsCardV2 from "./components/NewsCardV2.vue";
import BankNavi from "@/components/UrlbankNavi.vue";

export default {
  name: "",
  components: { NewsCardV2, BankNavi },
  data() {
    return {
      render: 0,
    };
  },
  computed: {
    user() {
      return this.$store.state.mail;
    },
    isNextPage() {
      return this.$store.state.vm.mPage < this.$store.state.vm.mPageMax;
    },
    isPrevPage() {
      return this.$store.state.vm.mPage > 0;
    },
  },
  updated() {
    if (this.$store.state.vm.magazines.length > this.$store.state.vm.mPageMax)
      this.isPrevNext = true;
  },
  mounted() {
    console.log("magazines mounted! ----------------------------------");
    onGetMags()
    this.render++;
  },
  methods: {
    getPage() {
      return this.$store.state.mPage;
    },
    getMax() {
      return this.$store.state.mPageMax;
    },
    getLimit() {
      return this.$store.state.mPageLimit;
    },

    prevPage() {
      if (this.$store.state.mPage > 0) {
        console.log("prevPage");
        //onMagazinePrevList();
      }
    },
    nextPage() {
      if (this.$store.state.mPage < this.$store.state.mPageMax) {
        console.log("nextPage");
        //onMagazineNextList();
      }
    },
    createMag: function () {
      onMagazineCreate().then((res) => {
        console.log(res);
      });
    },
    /*
    convert: function () {
      function sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
          currentDate = Date.now();
        } while (currentDate - date < milliseconds);
      }
  
      for (var mm in this.$store.state.magazines) {
        this.$store.state.magazines[mm].checkData();
        this.$store.commit("onMagazineSelect", mm);
        onArticleList(this.$store.state.magazines[mm].nodes).then(() => {
          for (var aa in this.$store.state.articles) {
            this.$store.state.articles[aa].checkData();
            onArticleUpdate(this.$store.state.articles[aa]);
          }
          onMagazineUpdate(this.$store.state.magazines[mm]);
          sleep(2500);
        });
        console.log(this.$store.state.magazines[mm]);
        if (mm > 0) break;
      */
  },
};
