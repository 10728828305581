<style>
.tagInput {
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.tagInput label {
  width: 30px;
  text-align: right;
  margin-right: 8px;
  color: #bbb;
}
.tagInput input {
  border-radius: 4px;
  margin: 2px 8px;
  width: 100%;
}
table {
  padding: 0;
  width: 100%;
}
button.delete:hover {
  background-color: #d00;
}
button.delete {
  text-align: cener;
  background-color: #b00;
  margin-left: 18px;
}
</style>

<template>
  <div class="tagInput">
    <table>
      <tr>
        <td width="40px">
          <label for="tag" :title="index">Tag </label><br />
          <label for="class" :title="index">Class </label>
        </td>
        <td width="90%">
          <input type="text" @change="change" id="tag" v-model="node.tag" />
          <input type="text" @change="change" id="class" v-model="node.class" />
        </td>
        <td width="40px">
          <button class="delete" @click="onDelete">X</button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "TagInput",
  props: ["dataIn", "index"],
  data() {
    return {
      node: {
        tag: "",
        class: "",
      },
    };
  },
  mounted() {
    this.node = this.dataIn;
  },
  methods: {
    change() {
      this.$emit("emchange", { idx: this.index, node: this.node });
    },
    onDelete() {
      this.$emit("emdelete", this.index);
    },
  },
};
</script>
