<template>
  <bank-navi :user="user"></bank-navi>
  <div class="py-0 container-fluid">
    <div class="">
      <h2>{{ newsTitle }}の記事一覧</h2>
      <p class="date" style="margin-top: -8px; margin-bottom: 8px">
        Created: {{ createdAt() }} / Updated: {{ updatedAt() }}
      </p>
      <a class="btn bg-gradient-success mt-1 mx-1" href="#" type="button" @click="back">もどる</a>

      <div class="card p-2">
        <div class="col-lg-11 col-md-11 mb-md-0 mb-0">
          <form role="form" class="text-start">
            <label style="font-size: 19px">号: </label><input type="date" v-model="date" onkeydown="return false"
              @change="updateDate" />
            <br />
          </form>
          <div class="ztable-responsive">
            <p>total letters = {{ getLen }}</p>
            <textarea v-model="memo" style="width: 100%; min-height: 80px" @change="updateMemo"></textarea>
            <table class="align-items-center news-table">
              <thead>
                <tr>
                  <th>idx</th>
                  <th>date</th>
                  <th>Article</th>
                  import DlgImportNews from "./components/DlgImportNews.vue";
                  <th>kind</th>
                  <th>len</th>
                  <th>pics</th>
                  <th v-on:ondrop="dropImg">Title Pictures</th>
                  <th>X</th>
                </tr>
              </thead>
              <tbody :key="counter">
                <tr v-for="(oo, index) in articleList" :key="index">
                  <td class="news-index" @click="edit(index)">
                    {{ index }}
                  </td>

                  <td class="news-date" @click="edit(index)">
                    {{ oo.date }}
                  </td>
                  <td class="news-text" @click="edit(index)">
                    <span v-if="oo.lines[0]">{{ oo.lines[0].text }} </span>
                  </td>
                  <td>
                    <button btn @click="setType(index, 'society')" :class="flgType(index, 'society')">
                      社会
                    </button>
                    <button btn @click="setType(index, 'economy')" :class="flgType(index, 'economy')">
                      経済
                    </button>
                    <button btn @click="setType(index, 'world')" :class="flgType(index, 'world')">
                      国際
                    </button>
                    <button btn @click="setType(index, 'life')" :class="flgType(index, 'life')">
                      LIFE
                    </button>
                    <button btn @click="setType(index, 'sports')" :class="flgType(index, 'sports')">
                      Sports
                    </button>
                  </td>
                  <td>{{ getNodeLen(oo.text) }}</td>
                  <td>{{ getPics(oo) }}</td>
                  <td class="news-pic" :id="picId(index)">
                    <div v-if="oo.lines[0]">
                      <img :src="getImage(oo)" style="max-height: 90px" />
                    </div>
                  </td>
                  <td>
                    <button btn @click="delNewsContent(index)" style="background-color: red">
                      X
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="text-left">
        <vsud-button class="my-2 mb-1" variant="gradient" color="success" @click="newArticle">
          Add News Content / 記事を追加
        </vsud-button>
        <dlg-import-news />

      </div>
    </div>
  </div>
</template>
<style>
button.society {
  background-color: #afffc0;
}

button.life {
  background-color: #ef8fd9;
}

button.world {
  background-color: #8a4456;
}

button.sports {
  background-color: #8fefb5;
}

button.economy {
  background-color: #efb18f;
}

.memo {
  min-height: 80px;
  width: 100%;
}

.news-table {
  border: solid 1px #c6cacf;
  width: 100%;
  margin: 20px 10px;
}

.news-table td:before {
  min-height: 20px !important;
}

.news-table .news-idx {
  border: solid 1px #c6cacf;
  width: 24px;
}

.news-table .news-text {
  width: 70%;
  min-height: 14px !important;
}

.news-table td {
  border: solid 1px #c6cacf;
}

.news-table .news-pic {
  min-width: 90px;
  min-height: 64px;
  padding: 0px;
  margin: 0px;
}

.news-pic {
  max-width: 160px !important;
  max-height: 60px !important;
}
</style>
<script>
import BankNavi from "@/components/UrlbankNavi.vue";
import VsudButton from "@/components/VsudButton.vue";
import DlgImportNews from "./components/DlgImportNews.vue";

/*
import {
  onMagazineMemoUpdate,
  onMagazineDateUpdate,
  onDeleteArticle,
  onArticleList,
  onArticleUpdate,
  onNewArticle,
} from "../RepositoryV2";
*/

import {
  getVmArticles,
  onVmArticleCreate,
  onVmArticleDelete,
  onMagazineMemoUpdate,
  onMagazineDateUpdate,
  //onVmArticleUpdate,
  //onMagazineUpdate,

} from "../../RepoVideo";


export default {
  name: "magazineEdit",
  components: {
    VsudButton,
    BankNavi,
    DlgImportNews
  },

  data() {
    return {
      mag: {},
      counter: 0,
      date: "",
      memo: "",
      images: { data: [] },
      errors: [],
      ctx: [{ text: "text", picId: 0, pic: "" }],
      pictures: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.mail;
    },
    articleList() {
      return this.$store.state.vm.articles;
    },

    getMemo() {
      return this.$store.state.vm.curMag.memo;
    },
    getImages() {
      // console.log(this.$store.state.curMag);
      // console.log(this.$store.state.curMag.images);
      return this.$store.state.vm.curMag.images;
    },
    newsTitle() {
      return this.$store.state.vm.curMag.date + "号";
    },
    getLen() {
      console.log(this.$store.state.vm.curMag);
      if (this.$store.state.vm.curMag.numChar) {
        return this.$store.state.vm.curMag.numChar;
      } else {
        return 0;
      }
      /*
      var cnt = 0;
      for (var nn in this.$store.state.curMag.nodes) {
        cnt += this.$store.state.curMag.nodes[nn].text.length;
      }
      return cnt;*/
    },
  },
  mounted() {
    if (null == this.$store.state.vm.curMag) this.$router.push("/magazines");
    this.memo = this.$store.state.vm.curMag.memo;
    this.date = this.$store.state.vm.curMag.date;
    /*
    onArticleList(this.$store.state.vm.curMag.nodes).then(() => {
      this.counter++;
    }); */
    console.log(this.$store.state.vm.curMag)
    getVmArticles(this.$store.state.vm.curMag._id)
    this.mag = this.$store.state.vm.curMag;
  },
  methods: {

    setType(idx, type) {
      console.log(idx);
      console.log(type);
      console.log(this.$store.state.vm.articles[idx]);
      this.$store.commit("onArticleTypeToggle", { idx, type });
      console.log(this.$store.state.vm.articles[idx]);
      //onArticleUpdate(this.$store.state.articles[idx]);
    },
    flgType(index, type) {
      if (this.$store.state.vm.articles.length < index) return "";

      if (!this.$store.state.vm.articles[index].aType)
        return "";

      if (this.$store.state.vm.articles[index].aType.indexOf(type) >= 0)
        return type;
      return "";
    },
    newsDate() {
      return this.mag.date;
    },

    createdAt() {
      var d = new Date(this.mag.createdAt);
      //return d.toString();
      return d.toLocaleString("ja");
    },
    updatedAt() {
      var d = new Date(this.mag.updatedAt);
      return d.toLocaleString("ja");
      //return d.toString();
    },

    getPics() {
      return 0
      //return oo.images.length;
    },
    getNodeLen(text) {
      return text.length;
    },
    isImage(image) {
      console.log(image);
      if (image.deleted) {
        return false;
      }
      return true;
    },
    delNewsContent(id) {
      console.log(id);
      if (id < 0) return;
      //if ([] == this.$store.state.vm.curMag.images) return null;
      if (this.$store.state.vm.articles.length <= id) return null;
      if (confirm("Are you sure deleting this content? (id=" + id + ")")) {
        onVmArticleDelete(this.$store.state.vm.articles[id])
      }
    },
    edit(idx) {
      this.$store.commit("onVmArticleSelect", idx);
      this.$router.push("/video/article-edit");
    },
    updateMemo() {
      onMagazineMemoUpdate(this.memo);
      //this.store.commit("onVmCurMagDate", this.memo);
      //onMagazineUpdate({ "_id": this.$store.state.vm.curMag._id, "memo": this.memo })
    },

    updateDate() {
      onMagazineDateUpdate(this.date);
    },
    back() {
      this.$router.push("/video/");
    },
    newArticle: function () {
      onVmArticleCreate(this.$store.state.vm.curMag._id).then((ret) => {
        console.log(ret)
        this.$store.commit(
          "onVmArticleSelect",
          this.$store.state.vm.articles.length - 1
        );
        console.log(ret);

      });
    },
    imgId(num) {
      return "img" + num.toString();
    },
    picId(num) {
      return "pic" + num.toString();
    },
    getImage(obj) {
      console.log(obj)
      if (obj.images.length > 0) return obj.images[0].url;
      else return "";
    },
  },
};
</script>
