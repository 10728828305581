<style>
input:hover {
  background-color: rgb(140, 132, 132);
}

.replace button.save {
  width: 80px;
  position: relative;
  font-size: 1.2em;
  margin-left: 40px;
  color: #333;
  background-color: #dd9fd4;
}

table.replace {
  width: 600px;
}
</style>

<template>
  <bank-navi :user="user"></bank-navi>
  <div class="replace">
    <div class="py-0 container-fluid">
      <div class="col-lg-12 col-12">
        <span class="title"> 置換辞書 (chrome)</span>
        <p>{{ message }}</p>
      </div>
      <button mt-10 pl-20 class="btn save" @click="save">Save</button>
      <div class="row ">
        <table class="replace">
          <!--

          <tr v-for="(ary, index) in $store.state.replaces" :key="index">
          -->
          <tr v-for="(ary, index) in replaceAry" :key="index">
            <ReplaceInput @emchange="emitChange" @emdelete="emitDelete" :dataIn="ary" :index="index" />
          </tr>
        </table>
      </div>
      <button class="btn dlg" @click="addAry()">Add</button>
    </div>
  </div>
</template>
<script>
import { loadReplaceJP, saveReplaceJP } from "../Repository";
import BankNavi from "@/components/UrlbankNavi.vue";
import ReplaceInput from "@/components/ReplaceInput.vue";
//import { tsImportEqualsDeclaration } from "@babel/types";

export default {
  name: "Pages",
  components: {
    BankNavi,
    ReplaceInput,
  },
  data() {
    return {
      message: "",
      render: 0,
      curSite: null,
      aryNames: {
        dates: "Dates",
        targets: "Targets",
        captures: "Captures",
        stops: "Stops",
        skips: "Skips",
        images: "Images",
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.mail;
    },
    replaceAry() {
      return this.$store.state.replaces;
    },


  },
  updated() { },
  mounted() {
    loadReplaceJP();
  },
  methods: {
    addAry() {
      this.$store.commit("onNewReplace", ["", ""])
    },
    emitChange(obj) {
      this.$store.commit("onUpdateReplace", obj)
    },
    emitDelete(idx) {
      console.log("del " + idx)
      this.$store.commit("onDeleteReplace", idx);
      this.$forceUpdate()
    },


    async save() {
      if (!confirm("セーブします")) return;
      await saveReplaceJP().then((res) => {
        if (res.status == "ok")
          this.message = "Save しました";
        else if (res.reason == "same data")
          this.message = "中止しました. reason:" + res.reason;
      });
    },
    branch(url) {
      this.$router.push(url);
    },
  },
};
</script>
